import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import {
  Box,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form'
import { Stack } from '@mui/system'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ListIcon from '@mui/icons-material/DragHandle'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { t } from 'i18next'
import { FileDataFragmentDoc, useDeleteFileMutation, useUploadFileMutation } from '@typings/graphql'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { ContentFile, isGraphQlFile } from '../mutation-helper/file'
import FileDragDrop from '../../FileDragDrop'

import { ExerciseTextEditor } from './components/ExerciseTextEditor'

import type { ContentEditorComponentProps } from '.'

type UpdateSortableListContentFormInput = {
  texts: {
    exercise: string,
    solution?: string
  }
  config: Record<string, any>
  items: SortableListItem[]
}

type SortableListItem = {
  key: string
  text: string
  file?: ContentFile | File | null
  order: number
  locked: boolean
}

type SortableListItemProps = {
  id: string
  index: number
  onDelete: () => void
}

const SortableListItemElement: React.FC<SortableListItemProps> = ({ id, index, onDelete }) => {
  const { control, register } = useFormContext<UpdateSortableListContentFormInput>()

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <Box
      key={id}
      display="flex"
      alignItems="center"
      gap={2}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{ cursor: 'grab' }}
        ref={setActivatorNodeRef}
        {...listeners}
      >
        <ListIcon />
      </Box>

      <Controller
        name={`items.${index}.locked`}
        control={control}
        render={({ field: { value, onChange } }) => (
          <IconButton onClick={() => onChange(!value)}>
            {value ? <LockIcon /> : <LockOpenIcon />}
          </IconButton>
        )}
      />

      <Box flex={1}>
        <Controller name={`items.${index}.file`}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => {
            return <FileDragDrop
              accept={{ 'image/*': ['.png', '.webp', '.jpg', '.jpeg'] }}
              width="100%"
              maxHeight={100}
              preview
              initialFile={isGraphQlFile(value) ? value : undefined}
              onFilesChanged={([file]) => onChange(file)}
            >
              <Typography>{ !value ? t('edit.poi.dragImage') : (value as any).fileName }</Typography>
            </FileDragDrop>
          }}
        />
      </Box>

      <Box flex={2}>
        <TextField sx={{ width: '100%' }} {...register(`items.${index}.text`, { required: true })} />
      </Box>

      <IconButton
        sx={{ ml: 2, alignSelf: 'center' }}
        onClick={onDelete}
      >
        <DeleteOutlineIcon />
      </IconButton>
    </Box>)
}

export const SortableListContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, onEdited }) => {
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const [uploadFile, { loading: isUploading, error }] = useUploadFileMutation()
  const [deleteFile] = useDeleteFileMutation()

  const defaultValues = useMemo<UpdateSortableListContentFormInput>(() => {
    const itemTextKeys = Object.keys(content.texts).filter((key) => key.startsWith('item_'))
    const itemIndices = itemTextKeys.map((key) => parseInt(key.split('_')[1]))
    const order: number[] = content.config.order ?? itemIndices

    return {
      texts: content.texts,
      config: content.config,
      items: order.length
        ? order.map((key) => ({
          key: `item_${key}`,
          text: content.texts[`item_${key}`],
          file: content.files.find((file) => file.key === `item_${key}`),
          order: order[key] ?? 0,
          locked: content.config.locked?.includes(key)
        }))
        : [{ key: 'item_1', text: '', order: 0, locked: false }]
    }
  }, [content])

  const sensors = useSensors(
    useSensor(PointerSensor)
  )

  const methods = useForm<UpdateSortableListContentFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'items'
  })

  const appendItem = () => {
    const items = methods.getValues('items')
    const indices = items.map((item) => parseInt(item.key.split('_')[1])).sort((a, b) => (a - b))
    const lastIndex = indices.length ? indices.pop() as number : 0

    append({ key: `item_${lastIndex + 1}`, text: '', order: lastIndex + 1, locked: false })
  }

  const canSave = useMemo(
    () => methods.formState.isValid && methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const onSubmit = methods.handleSubmit(async (submittedData) => {
    setLoading(true)

    const translatableTexts = {
      ...submittedData.texts,
      ...submittedData.items.reduce((acc, item) => {
        acc[item.key] = item.text
        return acc
      }, {} as Record<string, string>)
    }

    const texts = await syncContentTexts(content, translatableTexts)

    for (const item of submittedData.items) {
      if (item.file && !isGraphQlFile(item.file)) {
        await uploadFile({
          variables: {
            file: item.file,
            data: {
              key: item.key,
              model: 'Content',
              modelId: content.id,
              replace: true
            }
          },
          update: (cache, { data }) => {
            cache.modify({
              id: cache.identify({
                __typename: 'Content',
                id: content.id
              }),
              fields: {
                files (existingFiles = []) {
                  const newFileRef = cache.writeFragment({
                    data: data?.uploadFile,
                    fragment: FileDataFragmentDoc,
                    fragmentName: 'FileData'
                  })

                  return [...existingFiles, newFileRef]
                }
              }
            })
          }
        })
      }
    }

    for (const file of content.files) {
      if (!submittedData.items.find((item) => item.key === file.key)) {
        await deleteFile({
          variables: {
            id: file.id
          },
          update: (cache) => {
            cache.modify({
              id: cache.identify(content),
              fields: {
                files (existingFiles = []) {
                  return existingFiles.filter((existing: any) => existing.__ref !== `File:${file.id}`)
                }
              }
            })
          }
        })
      }
    }

    try {
      await updateContent(content.id, {
        nuggetId,
        texts,
        order: content.order,
        config: {
          ...submittedData.config,
          order: submittedData.items.map((item) => parseInt(item.key.split('_')[1])),
          locked: submittedData.items.filter((item) => item.locked).map((item) => parseInt(item.key.split('_')[1]))
        }
      })
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  })

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (!over) {
      return
    }

    if (active.id !== over.id) {
      const oldIndex = fields.findIndex(f => f.id === active.id)
      const newIndex = fields.findIndex(f => f.id === over.id)

      const newOrder = arrayMove(fields, oldIndex, newIndex)

      methods.setValue('items', newOrder, {
        shouldDirty: true
      })
    }
  }
  const textFields = useMemo(() => {
    return fields.map((field, index) => (
      <SortableListItemElement
        key={field.id}
        id={field.id}
        index={index}
        onDelete={() => remove(index)}
      />
    ))
  }, [fields])

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={onSubmit}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <ExerciseTextEditor />
          <Box>
            <Chip label={t('edit.content.sortableList')} size="small" sx={{ marginBottom: '8px' }} />

            <Stack spacing={2} width="50%" maxWidth="600px">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={fields}
                  strategy={verticalListSortingStrategy}
                >
                  {textFields}
                </SortableContext>
              </DndContext>

              <Button
                variant="outlined"
                sx={{ alignSelf: 'flex-start' }}
                onClick={appendItem}
              >
                <AddCircleOutlineIcon />
              </Button>
            </Stack>
          </Box>
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading || isUploading}
          uploadError={uploadError || error}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
