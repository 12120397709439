import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { getImageDimensions } from '@utils/imageDimensions'

import { ContentFile } from '../mutation-helper/file'

type Props = {
  file: ContentFile,
  index?: number,
  keyvisual?: boolean,
  imgClass?: boolean
}
export const MediaInfos: React.FC<Props> = (props) => {
  const {
    file,
    keyvisual = false,
    index = 0,
    imgClass = true
  } = props
  const [imgDimensions, setImgDimensions] = useState<{ width: number, height: number }[]>([])

  const addImgDimension = async (item: ContentFile, multifiles: boolean) => {
    try {
      const val = await getImageDimensions(item)

      if (multifiles) {
        setImgDimensions(prev => [...prev, val])
      } else {
        setImgDimensions([val])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const initImageInfos = () => {
    addImgDimension(file, false)
  }

  const infos = useMemo(
    () => {
      return <Stack style={ imgClass
        ? {
            position: 'absolute',
            bottom: '4px',
            right: '4px',
            textAlign: 'right',
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: '#fff',
            padding: '4px'
          }
        : {}}
      > {
          ((file as ContentFile)) && (
          <>
            { (file as ContentFile)?.fileSize &&
              <Typography variant="caption" sx={{ lineHeight: '1em' }}>{ (file as ContentFile)?.fileSize < 1000000
                ? (
                    ((file as ContentFile)?.fileSize / 1000).toFixed(2) + ' KB'
                  )
                : (
                    ((file as ContentFile)?.fileSize / 1000000).toFixed(2) + ' MB'
                  )}
              </Typography>
            }
            {
              imgDimensions.length > 0 &&
              <Typography variant="caption" sx={{ lineHeight: '1em' }}>{imgDimensions[index]?.height} x {imgDimensions[index]?.width} px</Typography>
            }
            </>
          )
      }
      </Stack>
    },
    [file, imgDimensions]
  )

  useEffect(() => {
    if (file?.type?.includes('image') || file?.mimeType?.includes('image') || keyvisual) { initImageInfos() }
  }, [file])

  return <Box>{ infos }</Box>
}
