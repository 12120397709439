import FileDragDrop from '@features/cms/components/FileDragDrop'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import {
  Box,
  Chip,
  Typography
} from '@mui/material'
import { UpdateContentInput, useUploadFileMutation } from '@typings/graphql'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { ContentFile, isGraphQlFile } from '../mutation-helper/file'
import { ConfigEditor } from '../components/ConfigEditor'
import { lottieConfigSchema } from '../config-schemas/lgs-kids/lottie'

import type { ContentEditorComponentProps } from '.'

type LottieContentFormInput = {
  animation: ContentFile | File | null,
  config: {
    trigger: 'autoplay' | 'tap',
    triggerPosition?: [number, number],
    hitSlop?: number,
  }
}

export const LottieContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const [uploadFile, { loading: isUploading, error }] = useUploadFileMutation()

  const defaultValues = useMemo<LottieContentFormInput>(() => {
    return {
      animation: content.files.find((file) => file.key === 'animation') || null,
      config: content.config || {}
    }
  }, [content])

  const methods = useForm<LottieContentFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const canSave = useMemo(
    () => methods.formState.isValid && methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const onSubmit = async (submittedData: LottieContentFormInput) => {
    setLoading(true)

    if (submittedData.animation && !isGraphQlFile(submittedData.animation)) {
      await uploadFile({
        variables: {
          file: submittedData.animation,
          data: {
            key: 'animation',
            model: 'Content',
            modelId: content.id,
            replace: true
          }
        }
      })
    }

    const data: UpdateContentInput = {
      nuggetId,
      texts: [],
      order: content.order,
      config: submittedData.config
    }

    try {
      await updateContent(content.id, data)
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <Box display="flex" gap={2}>
            <Box flex={1}>
              <Chip label={t('common.animation')} size="small" sx={{ marginBottom: '8px' }} />
              <Controller name="animation"
                control={methods.control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => {
                  return <FileDragDrop
                    accept={{ 'application/json': ['.json'] }}
                    width="100%"
                    height="100%"
                    preview
                    initialFile={isGraphQlFile(value) ? value : undefined}
                    onFilesChanged={([file]) => onChange(file)}
                  >
                    <Typography>{ !value ? t('edit.poi.dragLottie') : (value as any).fileName }</Typography>
                  </FileDragDrop>
                }}
              />
            </Box>

            <Box flex={1}>
              <ConfigEditor schema={lottieConfigSchema} />
            </Box>
          </Box>
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading || isUploading}
          uploadError={error || uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
