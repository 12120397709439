import FileDragDrop from '@features/cms/components/FileDragDrop'
import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { Box, Button, Chip, TextField, Typography, useTheme } from '@mui/material'
import { NuggetContent } from '@views/poi/EditContentPanel'
import React, { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'

import { ContentFile } from '../mutation-helper/file'

import { MediaInfos } from './MediaInfos'

type Props = {
  content: NuggetContent
}

export type InteractionCardTexts = {
  title: string,
  description?: string,
  alt: string,
  button: string,
}

export type EditableFile = {
  key: string,
  file: ContentFile | File | undefined | null,
  replaceId: string | null,
}

export type InteractionCardFormInputs = {
  interactionCardTexts: {
    key: string,
    value: string
  }[],
  descriptionImage: EditableFile
}

export const interactionCardTextKeys = ['title', 'description', 'alt', 'button'] as const

export const InteractionCardEditor: React.FC<Props> = ({ content }) => {
  const { t } = useTranslation()
  const { control, setValue, register } = useFormContext()
  const theme = useTheme()

  const texts = useMemo(() => {
    return interactionCardTextKeys.map((key) => ({
      key,
      value: content.texts[key] || ''
    }))
  }, [content])

  const descriptionImage = useMemo(() => ({
    key: 'description',
    file: content.files.find((file) => file.key === 'description') || null,
    replaceId: null
  }), [content])

  const previewCardTexts = useWatch({
    control,
    name: 'interactionCardTexts'
  })

  const previewCardImage = useWatch({
    control,
    name: 'descriptionImage.file'
  })

  const addImage = (file: File, oldFile: ContentFile) => {
    setValue('descriptionImage.file', file, { shouldDirty: true })

    if (oldFile) setValue('descriptionImage.replaceId', oldFile.id)
  }

  return (<Box display="flex">
    <Box sx={{ flex: '0 0 500px' }}>
      <TextField
        label={texts[0].key}
        sx={{ width: '500px', marginBottom: '60px' }}
        {...register('interactionCardTexts.0.value')}
      />
      <Chip label={texts[1].key} size="small" sx={{ marginBottom: '8px' }} />
      <MarkdownEditor
        name={'interactionCardTexts.1.value'}
        style={{ marginBottom: '60px' }}
      />
      <TextField
        label={texts[2].key}
        sx={{ width: '500px', marginBottom: '60px' }}
        {...register('interactionCardTexts.2.value')}
      />
      <TextField
        label={texts[3].key}
        sx={{ width: '500px', marginBottom: '60px' }}
        {...register('interactionCardTexts.3.value')}
      />
      <Chip label="Description" size="small" sx={{ marginBottom: '8px' }} />
      <FileDragDrop
        accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
        height="auto"
        minHeight="200px"
        preview
        limit={1}
        onFilesChanged={(files) => addImage(files[0], descriptionImage.file as ContentFile)}
      >
        {!descriptionImage.file
          ? <Typography>{t('edit.poi.dragImage')}</Typography>
          : <>
            <Box width="100%"
              height="100%"
              sx={{
                backgroundImage: `url(${(descriptionImage.file as ContentFile).url})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center center'
              }}
            />
            <MediaInfos file={descriptionImage.file as ContentFile}></MediaInfos>
          </>}
      </FileDragDrop>
    </Box>
    <Box paddingX={24} sx={{ pointerEvents: 'none' }} flexGrow={1}>
      <Box sx={{ borderRadius: 6, backgroundColor: '#F4F4F4', paddingX: 4, paddingTop: 2, paddingBottom: 4 }} width={350}>
        {previewCardTexts?.length &&
          <>
            <Box sx={{ fontSize: '24px', fontWeight: 'bold' }}>
              <ReactMarkdown>{previewCardTexts[0].value}</ReactMarkdown>
            </Box>
            <ReactMarkdown>{previewCardTexts[1].value}</ReactMarkdown>
          </>}
        {previewCardImage && <img
          style={{ borderRadius: '16px' }}
          width="100%"
          src={previewCardImage.url ? previewCardImage.url : URL.createObjectURL(previewCardImage)}
          alt="description"
        />}
        {previewCardTexts?.length && <Button
          sx={{
            backgroundColor: content.config.buttonProps?.bgColor || theme.palette.primary.main,
            color: '#fff',
            marginTop: 2,
            borderRadius: 12,
            paddingX: 2,
            width: '100%',
            textTransform: 'none',
            fontWeight: 'bold'
          }}
        >
          <PlayCircleIcon sx={{ marginRight: 2 }} />
            {previewCardTexts[3].value}
          </Button>}
      </Box>
    </Box>
  </Box>)
}
