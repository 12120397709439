import React, { useMemo } from 'react'
import Editor, { Monaco } from '@monaco-editor/react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { useTranslation } from 'react-i18next'

type Props = {
  name: string,
  width?: string | number,
  style?: Record<string, any>,
  schema?: Record<string, any>
}

export const JsonEditor: React.FC<Props> = ({ name, width, style, schema }) => {
  const { t } = useTranslation()
  const { control, getValues, setValue } = useFormContext()
  const [fullscreenModalOpen, setFullscreenModalOpen] = React.useState(false)

  const asObject = useMemo(() => {
    return typeof getValues(name) === 'object'
  }, [])

  const config = useWatch({
    control,
    name
  })

  const onMount = (editor: any, monaco: Monaco) => {
    if (!schema) {
      return
    }

    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [{
        uri: 'http://json-schema.org/draft-04/schema#',
        fileMatch: ['*'],
        schema
      }]
    })
  }

  const onJsonChanged = (newVal?: string) => {
    if (!asObject) {
      setValue(name, newVal, { shouldDirty: true })
      return
    }

    try {
      const obj = JSON.parse(newVal ?? '')

      setValue(name, obj, { shouldDirty: true })
    } catch (e) {

    }
  }

  return (
    <Box
      width={width}
      style={{ ...style, boxShadow: '0 0 0 1px #CFD7DD, 0 0 0 #CFD7DD, 0 1px 1px #CFD7DD' }}
    >
      <Controller
        name={name}
        rules={{
          validate: (value) => {
            if (asObject) {
              return true
            }

            try {
              JSON.parse(value)
              return true
            } catch (e) {
              return false
            }
          }
        }}
        control={control}
        render={() => {
          const parsedValue = asObject ? JSON.stringify(config, undefined, 2) : config
          return <Editor
            width={width}
            height="200px"
            language="json"
            defaultValue={parsedValue}
            value={parsedValue}
            onChange={onJsonChanged}
            onMount={onMount}
          />
        }}
      ></Controller>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexGrow={1}></Box>
        <IconButton onClick={() => setFullscreenModalOpen(true)}>
          <FullscreenIcon></FullscreenIcon>
        </IconButton>
      </Box>

      <Dialog open={fullscreenModalOpen} onClose={() => setFullscreenModalOpen(false)} sx={{ width: '100%', height: '100%' }}>
        <DialogContent>
          <Controller
            name={name}
            rules={{
              validate: (value) => {
                if (asObject) {
                  return true
                }

                try {
                  JSON.parse(value)
                  return true
                } catch (e) {
                  return false
                }
              }
            }}
            control={control}
            render={() => {
              const parsedValue = asObject ? JSON.stringify(config, undefined, 2) : config
              return <Editor
                width="900px"
                height="700px"
                language="json"
                defaultValue={parsedValue}
                value={parsedValue}
                onChange={onJsonChanged}
              />
            }}
          ></Controller>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFullscreenModalOpen(false)}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>
    </Box>)
}
