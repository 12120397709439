import React from 'react'
import * as ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import CoreProvider from '@providers/CoreProvider'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'
import { LicenseInfo } from '@mui/x-license-pro'

import './index.css'

LicenseInfo.setLicenseKey(import.meta.env.REACT_APP_MUI_LICENSE_TOKEN)

Sentry.init({
  enabled: import.meta.env.MODE !== 'development',

  environment: import.meta.env.MODE,

  dsn: 'https://0a57dba46a1a44b5b59495c0e7d9aa9f@sentry.kaleidoscode.de/3',
  integrations: [new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    )
  })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

const container = document.getElementById('root')

ReactDOM.createRoot(container as HTMLElement).render(
  <CoreProvider />
)
