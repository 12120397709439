import FileDragDrop from '@features/cms/components/FileDragDrop'
import { Box, Chip, Stack } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { ContentFile, isGraphQlFile, uploadFile } from '../mutation-helper/file'
import { syncContentTexts } from '../mutation-helper/text'

import { ExerciseTextEditor } from './components/ExerciseTextEditor'

import type { ContentEditorComponentProps } from '.'

type UpdatePanoramaImageContentFormInput = {
  image: ContentFile | File | undefined,
  blockedById: string | null,
  config: Record<string, any>,
  texts: {
    exercise: string,
    solution?: string
  }
}

export const PanoramaImageContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [uploadError, setUploadError] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)

  const defaultValues = useMemo(() => {
    return {
      image: content.files.find((file) => file.key === 'panorama'),
      blockedById: content.blockedById || null,
      config: content.config,
      texts: content.texts
    }
  }, [content])

  const methods = useForm<UpdatePanoramaImageContentFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const imageFile = useWatch({
    control: methods.control,
    name: 'image'
  })

  const panoramaImagePreviewUrl = useMemo(() => {
    if (imageFile instanceof File) {
      return URL.createObjectURL(imageFile)
    }

    return content.files[0]?.url
  }, [content, imageFile])

  const onSubmit = methods.handleSubmit(async (submittedData) => {
    setLoading(true)

    const texts = await syncContentTexts(content, submittedData.texts)

    if (!isGraphQlFile(submittedData.image)) {
      try {
        await uploadFile({
          file: submittedData.image,
          data: {
            key: 'panorama',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (e) {
        setUploadError(e)
      }
    }

    if (methods.formState.isDirty) {
      try {
        await updateContent(content.id, {
          nuggetId,
          order: content.order,
          type: content.type,
          blockedById: submittedData.blockedById || null,
          config: submittedData.config,
          texts
        })
        methods.reset()
      } catch (e) {
        setUploadError(e)
      }

      setLoading(false)
    }

    setShowSnackbar(true)
    refetch && refetch()
  })

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={onSubmit}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <ExerciseTextEditor />
        <Stack direction="row" spacing={2}>
          <Box flex={1}>
            <Chip label="Image" size="small" sx={{ mb: 1 }} />
            <Controller
              name="image"
              control={methods.control}
              render={({ field: { value, onChange } }) => {
                return <FileDragDrop
                  accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
                  height="300px"
                  preview
                  initialFile={!isGraphQlFile(value) ? undefined : value}
                  limit={1}
                  onFilesChanged={(files) => onChange(files[0])}
                />
              }}
            />
          </Box>
          <Box flex={1}>
            <Chip label={t('common.preview')} size="small" sx={{ mb: 1 }} />
            {panoramaImagePreviewUrl && <ReactPhotoSphereViewer key={panoramaImagePreviewUrl}
              src={panoramaImagePreviewUrl}
              height="300px"
              width="100%"
              container=""
              keyboard="fullscreen"
            />}
          </Box>
        </Stack>
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={uploadError}
        showSnackbar={showSnackbar}
        closeSnackbar={() => setShowSnackbar(false)}
      />
    </form>
  </FormProvider>
}
