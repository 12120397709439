import FileDragDrop from '@features/cms/components/FileDragDrop'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { FileDataFragmentDoc, useUpdateFileMutation, useUploadFileMutation } from '@typings/graphql'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CodeIcon from '@mui/icons-material/Code'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { ContentFile, isGraphQlFile } from '../mutation-helper/file'
import { ConfigEditor } from '../components/ConfigEditor'
import { mediaConfigSchema } from '../config-schemas/lgs-kids/media'

import { VoiceoverMarkerSelection } from './components/VoiceoverMarkerSelection'

import type { ContentEditorComponentProps } from '.'

type MediaContentFormInput = {
  texts: {
    title?: string
    button?: string
  }
  items: MediaItem[]
  config: Record<string, any>
}

type MediaItem = {
  key: string
  text?: string
  file: ContentFile | File | null,
  voiceover?: string
}

export const MediaContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [configEditFilePath, setConfigEditFilePath] = useState<string | null>(null)
  const [uploadError, setUploadError] = useState<any>(null)

  const [uploadFile, { loading: isUploading, error }] = useUploadFileMutation()
  const [updateFile] = useUpdateFileMutation()

  const defaultValues = useMemo<MediaContentFormInput>(() => {
    const itemTextKeys = content.files.filter((file) => file.key.startsWith('item_')).map(({ key }) => key)
    itemTextKeys.sort((a, b) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1]))

    return {
      texts: content.texts,
      items: itemTextKeys.length
        ? itemTextKeys.map((key) => ({
          key,
          text: content.texts[key],
          file: content.files.find(file => file.key === key) as ContentFile,
          voiceover: content.config?.voiceoverMarker?.[key]
        }))
        : [{ key: 'item_1', text: '', file: null }],
      config: content.config || {}
    }
  }, [content])

  const methods = useForm<MediaContentFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'items'
  })

  const appendItem = () => {
    const items = methods.getValues('items')
    const indices = items.map((item) => parseInt(item.key.split('_')[1])).sort((a, b) => (a - b))
    const lastIndex = indices.length ? indices.pop() as number : 0

    append({ key: `item_${lastIndex + 1}`, text: '', file: null })
  }

  const canSave = useMemo(
    () => methods.formState.isValid && methods.formState.isDirty,
    [methods.formState.isDirty, methods.formState.isValid]
  )

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const onSubmit = async (submittedData: MediaContentFormInput) => {
    setLoading(true)

    const translatableTexts = {
      ...submittedData.texts,
      ...submittedData.items.reduce((acc, item) => {
        acc[item.key] = item.text ?? ''
        return acc
      }, {} as Record<string, string>)
    }

    const texts = await syncContentTexts(content, translatableTexts)

    for (const item of submittedData.items) {
      const { file } = item

      if (!file) {
        continue
      }

      if (isGraphQlFile(file)) {
        await updateFile({
          variables: {
            id: file.id,
            data: {
              key: item.key,
              config: file.config
            }
          }
        })
      } else {
        await uploadFile({
          variables: {
            file,
            data: {
              key: item.key,
              model: 'Content',
              modelId: content.id,
              replace: true
            }
          },
          update: (cache, { data }) => {
            cache.modify({
              id: cache.identify({
                __typename: 'Content',
                id: content.id
              }),
              fields: {
                files (existingFiles = []) {
                  const newFileRef = cache.writeFragment({
                    data: data?.uploadFile,
                    fragment: FileDataFragmentDoc,
                    fragmentName: 'FileData'
                  })

                  return [...existingFiles, newFileRef]
                }
              }
            })
          }
        })
      }
    }

    console.log(submittedData.config, submittedData.items)
    try {
      await updateContent(content.id, {
        nuggetId,
        texts,
        order: content.order,
        config: {
          ...submittedData.config,
          voiceoverMarker: submittedData.items.reduce((acc, item) => {
            if (item.voiceover) {
              acc[item.key] = item.voiceover
            }
            return acc
          }, {} as Record<string, string>)
        }
      })
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  const textFields = useMemo(() => {
    const fieldsSorted = [...fields]
    fieldsSorted.sort((a, b) => parseInt(a.key.split('_')[1]) - parseInt(b.key.split('_')[1]))

    return fieldsSorted.map((field, index) => (
      <Box key={field.id} display="flex" alignItems="flex-start" gap={2} width="50%">
        <Box flex={1} display="flex" flexDirection="column" gap={2}>
          <Box display="inline" bgcolor="#f5f5f5" borderRadius={1} p={1} alignSelf="flex-start">
            <Typography fontSize="small" sx={{ opacity: 0.7 }}>{field.key.split('_')[1]}</Typography>
          </Box>

          <Box flex={1}
            display="flex"
            flexDirection="row"
            alignItems="stretch"
            justifyContent="stretch"
          >
            <Controller name={`items.${index}.file`}
              control={methods.control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => {
                return <FileDragDrop
                  accept={{ 'image/*': ['.png', '.webp', '.jpg', '.jpeg'], 'video/*': ['.mp4'] }}
                  width="100%"
                  maxHeight={200}
                  preview
                  initialFile={isGraphQlFile(value) ? value : undefined}
                  onFilesChanged={([file]) => onChange(file)}
                >
                  <Typography>{ !value ? t('edit.poi.dragImage') : (value as any).fileName }</Typography>
                </FileDragDrop>
              }}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <TextField sx={{ flex: 2 }} {...methods.register(`items.${index}.text`, { required: true })} />
            <Box width="250px">
              <VoiceoverMarkerSelection name={`items.${index}.voiceover`} />
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignSelf="stretch" flexDirection="column" alignItems="center" justifyContent="center">
          <Tooltip title={!isGraphQlFile(field.file) ? t('edit.content.toolTips.uploadFirst') : ''}>
            <span>
              <IconButton
                disabled={!isGraphQlFile(field.file)}
                onClick={() => setConfigEditFilePath(`items.${index}.file.config`)}
              >
                <CodeIcon />
              </IconButton>
            </span>
          </Tooltip>
          <IconButton
            onClick={() => remove(index)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      </Box>
    ))
  }, [fields])

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
          <Box flex={1}>
            <Chip label={t('common.title')} size="small" sx={{ marginBottom: '8px' }} />
            <Box>
              <TextField sx={{ width: '100%' }} {...methods.register('texts.title')} />
            </Box>
          </Box>

          <Box flex={1}>
            <Chip label={t('common.buttonText')} size="small" sx={{ marginBottom: '8px' }} />
            <Box>
              <TextField sx={{ width: '100%' }} placeholder={t('common.next')} {...methods.register('texts.button')} />
            </Box>
          </Box>
        </Box>

        <Box>
          <Chip label={t('edit.content.choices')} size="small" sx={{ marginBottom: '8px' }} />

          <Stack spacing={4}>
            {textFields}

            <Button
              variant="outlined"
              sx={{ alignSelf: 'flex-start' }}
              onClick={appendItem}
            >
              <AddCircleOutlineIcon />
            </Button>
          </Stack>
        </Box>
      </Stack>

      <Dialog open={!!configEditFilePath} onClose={() => setConfigEditFilePath(null)}>
        <DialogContent>
          <Box sx={{ width: 500, maxWidth: '80vw' }}>
            {configEditFilePath && <ConfigEditor name={configEditFilePath} schema={mediaConfigSchema} />}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfigEditFilePath(null)}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>

      <FormFooterBar
        disabled={!canSave}
        loading={loading || isUploading}
        uploadError={error || uploadError}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
