import { NuggetContent } from '@views/poi/EditContentPanel'

import { BalanceCheckContentEditor } from './BalanceCheckContentEditor'

export type ContentEditorComponentProps = {
  nuggetId: string,
  content: NuggetContent,
  refetch?: Function,
}

export const contentTypeEditors = {
  balanceCheck: BalanceCheckContentEditor
}

export default contentTypeEditors
