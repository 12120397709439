import { Box, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import ReactAudioPlayer from 'react-audio-player'

import { MediaInfos } from './content-type-editors/components/MediaInfos'

type FilePreviewData = {
  type: string,
  url: string,
  fileName: string,
  fileSize?: number
}

type Props = {
  preview: FilePreviewData[]
}

const getStyle = (preview: FilePreviewData[]) => {
  return preview.length > 1
    ? {
        maxWidth: '30%',
        height: '30%',
        margin: '8px'
      }
    : {
        height: '100%',
        maxWidth: '100%'
      }
}

const ImagePreview: React.FC<Props> = ({ preview }) => {
  const style = getStyle(preview)

  const previewImages = useMemo(() => preview.map((img) => (
    <><img key={img.fileName} src={img.url} style={style} />
      <MediaInfos file={img}></MediaInfos>
    </>
  )), [preview])

  return (
    <>
      {previewImages.length > 1
        ? <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="flex-start"
            width="100%"
            height="100%"
            overflow="auto"
        >
          {previewImages}
        </Box>
        : <><img key={preview[0].fileName} src={preview[0].url} style={{ ...style, objectFit: 'contain' }} />
          <MediaInfos file={preview[0]}></MediaInfos>
        </>}
    </>
  )
}

const AudioPreview: React.FC<Props> = ({ preview }) => {
  const style = getStyle(preview)

  return <Box style={{
    ...style,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  }}
    sx={{
      'audio::-webkit-media-controls-volume-control-container': {
        display: 'none !important;'
      },
      'audio::-webkit-media-controls-mute-button': {
        display: 'none !important;'
      },
      'audio::-webkit-media-controls-volume-slider': {
        display: 'none !important;'
      }
    }}
  >
    <ReactAudioPlayer
      src={preview[0].url}
      style={{
        width: '100%',
        maxWidth: '300px',
        height: '32px'
      }}
      controls
    />
    <Typography mt={2}>{preview[0].fileName}</Typography>
    <MediaInfos file={preview[0]}></MediaInfos>
  </Box>
}

const VideoPreview: React.FC<Props> = ({ preview }) => {
  const style = getStyle(preview)

  return <Box style={style}>
    <video autoPlay loop style={{ width: '100%', height: '100%' }}>
      <source src={preview[0].url} type={preview[0].type} />
    </video>
  </Box>
}

const LottiePreview: React.FC<Props> = ({ preview }) => {
  const style = getStyle(preview)

  return <Box style={{ ...style, position: 'relative' }}>
    <Player
      src={preview[0].url}
      autoplay
      loop
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid meet'
      }}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0
      }}
    >
    </Player>
  </Box>
}

const DefaultPreview: React.FC<Props> = ({ preview }) => {
  const style = getStyle(preview)

  return <Box style={style}>
    <Typography>{preview[0].fileName}</Typography>
  </Box>
}

const fileFormatComponents = {
  image: ImagePreview,
  video: VideoPreview,
  audio: AudioPreview,
  'application/json': LottiePreview
  // 'text/html'?: string[]
}

export const FilePreview: React.FC<Props> = ({ preview }) => {
  const mime = preview[0].type.split('/')[0]

  const PreviewComponent = fileFormatComponents[mime as keyof typeof fileFormatComponents] ??
    fileFormatComponents[preview[0].type as keyof typeof fileFormatComponents] ?? DefaultPreview

  return <PreviewComponent preview={preview} />
}
