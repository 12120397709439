import { ContentFile } from '@features/cms/components/content-type-editors/mutation-helper/file'
export const getImageDimensions = (file: ContentFile): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }

    img.onerror = reject
    img.src = file.url
  })
}
