import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Chip,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import {
  PublishTourModeUpdateMutation,
  useCanUpdateTourModeQuery,
  useCreateTourModeMutation,
  useDeleteTourModeMutation,
  usePublishTourModeUpdateMutation,
  useTourModeQuery,
  useUpdateTourModeMutation
} from '@typings/graphql'
import { convertToTextObject } from '@utils/texts'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import DeleteIcon from '@mui/icons-material/Delete'
import UpdateIcon from '@mui/icons-material/Update'
import { ConfirmDialog } from '@features/cms/components/dialogs/ConfirmDialog'
import { ContentFile, uploadFile } from '@features/cms/components/content-type-editors/mutation-helper/file'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { createThemeColors } from '@utils/color'
import { ContentTypeEditors } from '@features/cms/components/content-type-editors'
import { LoadingButton } from '@mui/lab'
import './ModeEdit.css'
import { FileList } from '@features/cms/components/FileList'
import { ConfigEditor } from '@features/cms/components/content-type-editors/components/ConfigEditor'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'
import { lgsModeConfigSchema } from '@features/cms/config-schemas/tour-mode/lgs'
import { lgsKidsModeConfigSchema } from '@features/cms/config-schemas/tour-mode/lgs-kids'
import { crimeModeConfigSchema } from '@features/cms/config-schemas/tour-mode/crime'
import { defaultModeConfigSchema } from '@features/cms/config-schemas/tour-mode/default'
import { EditIntroDialog } from '@features/cms/components/dialogs/EditIntroDialog'

type ModeFormInput = {
  texts: {
    title: string
  },
  ageFrom: number,
  ageTo: number,
  duration: number,
  distance: number,
  contributors: string,
  config: {
    backgroundType: 'image' | 'video' | 'default',
    nuggetBackgroundType: 'image' | 'video' | 'default',
    sectionOrderMarkerType: 'default' | 'custom',
  },
  primaryColor: string,
  secondaryColor: string,
  bodyFont: string,
  headingFont: string,
  fontScaling: string,
  sectionSelectionBackground: ContentFile | File | undefined,
  nuggetBackground: ContentFile | File | undefined,
  sectionOrderMarkerBackground: ContentFile | File | undefined,
  type: string
}

const PRIMARY_COLOR_DEFAULT = '#5856D6'
const SECONDARY_COLOR_DEFAULT = '#10cf72'

const FONTS = {
  DMSans: 'DM Sans',
  PatrickHand: 'Patrick Hand',
  OpenSans: 'Open Sans',
  CourierPrime: 'Courier Prime'
}

const configSchemas = {
  default: defaultModeConfigSchema,
  lgs: lgsModeConfigSchema,
  'lgs-kids': lgsKidsModeConfigSchema,
  crime: crimeModeConfigSchema
}

const ModeEdit = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [uploadError, setUploadError] = useState<any>()
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [showPublishInfoDialog, setShowPublishInfoDialog] = useState(false)
  const [publishInfo, setPublishInfo] = useState<PublishTourModeUpdateMutation['publishTourModeUpdate']>()

  const { id, modeId } = useParams()
  const { data: tourMode, refetch, loading } = useTourModeQuery({ variables: { id: modeId as string }, skip: !modeId })
  const { data: canUpdateTourMode, loading: updateCheckLoading, refetch: recheckUpdate } = useCanUpdateTourModeQuery({
    variables: { id: modeId as string },
    fetchPolicy: 'network-only',
    skip: !modeId
  })

  const mode = tourMode?.tourMode

  const configSchema = useMemo(() => {
    if (!mode?.type) return undefined

    return (configSchemas as any)[mode.type]
  }, [mode?.type])

  const [createTourMode, { loading: isCreating }] = useCreateTourModeMutation()
  const [updateTourMode, { loading: isUpdating }] = useUpdateTourModeMutation()
  const [deleteTourMode] = useDeleteTourModeMutation()
  const [publishTourModeUpdate, { loading: isPublishing }] = usePublishTourModeUpdateMutation()

  const defaultValues = useMemo<ModeFormInput>(() => ({
    texts: {
      title: mode?.texts.title || mode?.title || ''
    },
    ageFrom: mode?.ageFrom || 16,
    ageTo: mode?.ageTo || 99,
    duration: mode?.duration || 0,
    distance: mode?.distance || 0,
    contributors: mode?.contributors || '',
    config: {
      ...mode?.config,
      backgroundType: mode?.config?.backgroundType || 'default',
      nuggetBackgroundType: mode?.config?.nuggetBackgroundType || 'default',
      sectionOrderMarkerType: mode?.config?.sectionOrderMarkerType || 'default'
    },
    bodyFont: mode?.theme?.fonts?.body || 'DMSans',
    headingFont: mode?.theme?.fonts?.heading || 'DMSans',
    fontScaling: String(mode?.theme?.fonts?.scaling || 1),
    primaryColor: mode?.theme?.colors?.primary ? mode.theme.colors.primary['500'] : PRIMARY_COLOR_DEFAULT,
    secondaryColor: mode?.theme?.colors?.secondary ? mode.theme.colors.secondary['500'] : SECONDARY_COLOR_DEFAULT,
    nuggetBackground: mode?.files.find(({ key }) => key === 'nuggetBackground') || undefined,
    sectionSelectionBackground: mode?.files.find(({ key }) => key === 'sectionSelectionBackground') || undefined,
    sectionOrderMarkerBackground: mode?.config?.sectionOrderMarkerBackground || undefined,
    type: mode?.type || 'default'
  }), [mode])

  const methods = useForm<ModeFormInput>({
    defaultValues
  })

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  useUnsavedChangesAlert(methods.formState.isDirty)

  const primaryColor = useWatch({
    control: methods.control,
    name: 'primaryColor'
  })

  const secondaryColor = useWatch({
    control: methods.control,
    name: 'secondaryColor'
  })

  const backgroundType = useWatch({
    control: methods.control,
    name: 'config.backgroundType'
  })

  const nuggetBackgroundType = useWatch({
    control: methods.control,
    name: 'config.nuggetBackgroundType'
  })

  const sectionOrderMarkerType = useWatch({
    control: methods.control,
    name: 'config.sectionOrderMarkerType'
  })

  const onSubmit = async (submittedData: ModeFormInput) => {
    const texts = Object.keys(submittedData.texts)
      .map((key) => convertToTextObject(key, submittedData.texts[key as keyof typeof submittedData.texts]))

    if (mode?.id) {
      let themeColors = mode?.theme.colors
      if (mode?.theme.colors?.primary[500] !== submittedData.primaryColor || mode?.theme.colors?.secondary[500] !== submittedData.secondaryColor) {
        themeColors = createThemeColors(submittedData.primaryColor, submittedData.secondaryColor)
      }

      try {
        await updateTourMode({
          variables: {
            data: {
              ageFrom: submittedData.ageFrom,
              ageTo: submittedData.ageTo,
              duration: submittedData.duration,
              distance: submittedData.distance,
              contributors: submittedData.contributors,
              config: submittedData.config,
              type: submittedData.type,
              theme: {
                colors: themeColors,
                fonts: {
                  heading: submittedData.headingFont,
                  body: submittedData.bodyFont,
                  scaling: parseFloat(submittedData.fontScaling.replace(',', '.'))
                }
              },
              texts
            },
            id: mode.id
          }
        })

        if ((submittedData.sectionSelectionBackground as any) && !(submittedData.sectionSelectionBackground as any)?.id) {
          try {
            await uploadFile({
              file: submittedData.sectionSelectionBackground,
              data: {
                key: 'sectionSelectionBackground',
                replace: true,
                model: 'TourMode',
                modelId: mode.id
              }
            })
          } catch (e) {
            setUploadError(e)
          }
        }

        if ((submittedData.nuggetBackground as any) && !(submittedData.nuggetBackground as any)?.id) {
          try {
            await uploadFile({
              file: submittedData.nuggetBackground,
              data: {
                key: 'nuggetBackground',
                replace: true,
                model: 'TourMode',
                modelId: mode.id
              }
            })
          } catch (e) {
            setUploadError(e)
          }
        }

        if ((submittedData.sectionOrderMarkerBackground as any) && !(submittedData.sectionOrderMarkerBackground as any)?.id) {
          try {
            await uploadFile({
              file: submittedData.sectionOrderMarkerBackground,
              data: {
                key: 'sectionOrderMarkerBackground',
                replace: true,
                model: 'TourMode',
                modelId: mode.id
              }
            })
          } catch (e) {
            setUploadError(e)
          }
        }
      } catch (e) {
        setUploadError(e)
      }
    } else {
      const themeColors = createThemeColors(submittedData.primaryColor, submittedData.secondaryColor)

      try {
        const result = await createTourMode({
          variables: {
            data: {
              ageFrom: submittedData.ageFrom,
              ageTo: submittedData.ageTo,
              duration: submittedData.duration,
              distance: submittedData.distance,
              config: submittedData.config,
              type: submittedData.type,
              theme: {
                colors: themeColors,
                fonts: {
                  heading: submittedData.headingFont,
                  body: submittedData.bodyFont,
                  scaling: parseFloat(submittedData.fontScaling.replace(',', '.'))
                }
              },
              texts,
              tourId: id!
            }
          }
        })

        if (result.data && (submittedData.sectionSelectionBackground as any) && !(submittedData.sectionSelectionBackground as any)?.id) {
          try {
            await uploadFile({
              file: submittedData.sectionSelectionBackground,
              data: {
                key: 'sectionSelectionBackground',
                replace: true,
                model: 'TourMode',
                modelId: result.data.createTourMode.id
              }
            })
          } catch (e) {
            setUploadError(e)
          }
        }

        if (result.data && (submittedData.nuggetBackground as any) && !(submittedData.nuggetBackground as any)?.id) {
          try {
            await uploadFile({
              file: submittedData.nuggetBackground,
              data: {
                key: 'nuggetBackground',
                replace: true,
                model: 'TourMode',
                modelId: result.data.createTourMode.id
              }
            })
          } catch (e) {
            setUploadError(e)
          }
        }

        if (result.data && (submittedData.sectionOrderMarkerBackground as any) && !(submittedData.sectionOrderMarkerBackground as any)?.id) {
          try {
            await uploadFile({
              file: submittedData.sectionOrderMarkerBackground,
              data: {
                key: 'sectionOrderMarkerBackground',
                replace: true,
                model: 'TourMode',
                modelId: result.data.createTourMode.id
              }
            })
          } catch (e) {
            setUploadError(e)
          }
        }

        if (!mode?.id) {
          navigate(`/tour/${id}/mode/${result.data?.createTourMode.id}`)
        }
      } catch (e) {
        setUploadError(e)
      }
    }

    setShowSnackbar(true)
    await refetch()
  }

  const addFile = (file: File, key: 'sectionSelectionBackground' | 'nuggetBackground' | 'sectionOrderMarkerBackground') => {
    methods.setValue(key, file, { shouldDirty: true })
  }

  const onConfirmDelete = async () => {
    try {
      await deleteTourMode({
        variables: {
          id: mode!.id
        }
      })

      navigate(`/tour/${id}`)
    } catch (e) {
      console.log(e)
    }
  }

  const publishUpdate = async () => {
    const { data } = await publishTourModeUpdate({
      variables: {
        id: mode!.id
      }
    })

    setPublishInfo(data?.publishTourModeUpdate)
    setShowPublishInfoDialog(!!data?.publishTourModeUpdate)
    recheckUpdate()
  }

  const backgroundImage = useMemo(() => {
    const file = mode?.files.find(({ key }) => key === 'sectionSelectionBackground')

    return file?.mimeType.includes('image') ? file : undefined
  }, [mode])

  const backgroundVideo = useMemo(() => {
    const file = mode?.files.find(({ key }) => key === 'sectionSelectionBackground')

    return file?.mimeType.includes('video') ? file : undefined
  }, [mode])

  const nuggetBackgroundImage = useMemo(() => {
    const file = mode?.files.find(({ key }) => key === 'nuggetBackground')

    return file?.mimeType.includes('image') ? file : undefined
  }, [mode])

  const nuggetBackgroundVideo = useMemo(() => {
    const file = mode?.files.find(({ key }) => key === 'nuggetBackground')

    return file?.mimeType.includes('video') ? file : undefined
  }, [mode])

  const sectionOrderMakerImage = useMemo(() => {
    return mode?.files.find(({ key }) => key === 'sectionOrderMarkerBackground')
  }, [mode])

  if (!mode && loading) {
    return <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  }

  return <FormProvider {...methods} >
    <form
      style={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden', flex: 1 }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Box
        p={4}
        flex={1}
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        gap={{ xs: 4, lg: 12 }}
        sx={{ overflowY: 'auto' }}
      >
        <Box flex={1} display="flex" flexDirection="column" gap={4}>
          <TextField
            error={!!methods.formState.errors.texts?.title}
            sx={{ width: '100%' }}
            label={t('common.title')}
            helperText={!!methods.formState.errors.texts?.title && t('common.required')}
            InputLabelProps={{ shrink: true }}
            {...methods.register('texts.title', { required: true })}
          />
          <Box display="flex" flexDirection="row" gap={4}>
            <Controller
              control={methods.control}
              name="ageFrom"
              rules={{ required: true }}
              render={({ field: { onChange, value }, fieldState: { error } }) =>
                <TextField
                  value={value}
                  onChange={(v) => onChange(v.target.value ? undefined : parseInt(v.target.value))}
                  type="number"
                  error={!!error}
                  sx={{ width: '100%' }}
                  label={t('edit.mode.ageFrom')}
                  helperText={!!error && t('common.required')}
                  InputLabelProps={{ shrink: true }}
                />
                }
            />
            <Controller
              control={methods.control}
              name="ageTo"
              rules={{ required: true }}
              render={({ field: { onChange, value }, fieldState: { error } }) =>
                <TextField
                  value={value}
                  onChange={(v) => onChange(!v.target.value ? '' : parseInt(v.target.value))}
                  type="number"
                  error={!!error}
                  sx={{ width: '100%' }}
                  label={t('edit.mode.ageTo')}
                  helperText={!!error && t('common.required')}
                  InputLabelProps={{ shrink: true }}
                />
                }
            />
          </Box>
          <Box display="flex" flexDirection="row" gap={4}>
            <Controller
              control={methods.control}
              name="duration"
              rules={{ required: true }}
              render={({ field: { onChange, value }, fieldState: { error } }) =>
                <TextField
                  value={value}
                  onChange={(v) => onChange(!v.target.value ? '' : parseInt(v.target.value))}
                  type="number"
                  error={!!error}
                  sx={{ width: '100%' }}
                  label={t('edit.mode.duration')}
                  helperText={!!error && t('common.required')}
                  InputLabelProps={{ shrink: true }}
                />
                }
            />
            <Controller
              control={methods.control}
              name="distance"
              rules={{ required: true }}
              render={({ field: { onChange, value }, fieldState: { error } }) =>
                <TextField
                  value={value}
                  onChange={(v) => onChange(!v.target.value ? '' : parseInt(v.target.value))}
                  type="number"
                  error={!!error}
                  sx={{ width: '100%' }}
                  label={t('edit.mode.distance')}
                  helperText={!!error && t('common.required')}
                  InputLabelProps={{ shrink: true }}
                />
                }
            />
          </Box>

          <Box mb={4}>
            <Chip label={t('edit.mode.contributors')} sx={{ marginBottom: '16px' }} />
            <MarkdownEditor
              name={'contributors'}
              width="100%"
            />
          </Box>
        </Box>
        <Box flex={1} display="flex" flexDirection="column" gap={4}>
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <TextField
              label={t('common.primaryColor')}
              {...methods.register('primaryColor')}
              id="primaryColor"
              sx={{ flex: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Badge sx={{ backgroundColor: primaryColor, width: '20px', height: '20px' }}></Badge>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              label={t('common.secondaryColor')}
              {...methods.register('secondaryColor')}
              id="secondaryColor"
              sx={{ flex: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Badge sx={{ backgroundColor: secondaryColor, width: '20px', height: '20px' }}></Badge>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={4}
          >
            <Box flex={1} width="50%">
              <InputLabel id="mode-body-font-label">{t('common.bodyFont')}</InputLabel>
              <Controller
                control={methods.control}
                name="bodyFont"
                render={({ field: { onChange, value } }) =>
                  <Select
                    variant='standard'
                    labelId="mode-body-font-label"
                    sx={{ width: '100%', fontFamily: FONTS[value as keyof typeof FONTS] }}
                    value={value}
                    label={t('common.bodyFont')}
                    onChange={event => onChange(event.target.value)}
                  >
                    {Object.entries(FONTS).map(([key, font]) => {
                      return <MenuItem sx={{ fontFamily: font }} key={key} value={key}>{font}</MenuItem>
                    })}
                  </Select>
                  }
              />
            </Box>
            <Box flex={1} width="50%">
              <InputLabel id="mode-heading-font-label">{t('common.headingFont')}</InputLabel>
              <Controller
                control={methods.control}
                name="headingFont"
                render={({ field: { onChange, value } }) =>
                  <Select
                    variant='standard'
                    labelId="mode-heading-font-label"
                    sx={{ width: '100%', fontFamily: FONTS[value as keyof typeof FONTS] }}
                    value={value}
                    label={t('common.bodyFont')}
                    onChange={event => onChange(event.target.value)}
                  >
                    {Object.entries(FONTS).map(([key, font]) => {
                      return <MenuItem sx={{ fontFamily: font }} key={key} value={key}>{font}</MenuItem>
                    })}
                  </Select>
                  }
              />
            </Box>
          </Box>
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <TextField
              label={t('edit.mode.fontScaling')}
              sx={{ flex: 1 }}
              inputProps={{
                type: 'number',
                step: 0.1,
                min: 0.1,
                max: 3
              }}
              {...methods.register('fontScaling')}
            />
            <Box flex={1} />
          </Box>
          {mode && <Box><FileList files={mode.files} model='TourMode' modelId={mode.id} /></Box>}
          <ConfigEditor schema={configSchema} />
          {mode && <EditIntroDialog mode={mode} />}
        </Box>
        <Box flex={1} display="flex" flexDirection="column" gap={4}>
          <Controller
            control={methods.control}
            name="type"
            render={({ field: { onChange, value } }) =>
              <Autocomplete
                sx={{ width: '200px' }}
                value={value}
                disableClearable
                freeSolo
                options={Object.keys(ContentTypeEditors)}
                renderInput={(params) => <TextField {...params} label={t('common.type')} /> }
                onInputChange={(_, newValue) => onChange(newValue)}
              />
              }
          />

          <Box mr={2}>
            <InputLabel id="background-label">{t('common.background')}</InputLabel>
            <Controller
              control={methods.control}
              name="config.backgroundType"
              render={({ field: { onChange, value } }) => <Select
                variant='standard'
                labelId="background-label"
                sx={{ width: '200px' }}
                value={value}
                defaultValue="default"
                label={t('common.background')}
                onChange={event => onChange(event.target.value)}
              >
                <MenuItem value="default">{t('common.default')}</MenuItem>
                <MenuItem value="image">{t('common.image')}</MenuItem>
                <MenuItem value="video">{t('common.video')}</MenuItem>
              </Select>
            }
            />

          </Box>
          {backgroundType === 'image' && <FileDragDrop
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
            minHeight="200px"
            preview
            limit={1}
            onFilesChanged={(files) => addFile(files[0], 'sectionSelectionBackground')}
          >
            {!backgroundImage
              ? <Typography>{t('edit.poi.dragImage')}</Typography>
              : <Box width="100%"
                  height="100%"
                  sx={{
                    backgroundImage: `url(${backgroundImage.url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center'
                  }}
              />}
          </FileDragDrop>}
          {backgroundType === 'video' && <>
            <FileDragDrop
              accept={{ 'video/*': ['.mp4'] }}
              limit={1}
              preview
              style={{ marginBottom: '32px' }}
              onFilesChanged={(files) => addFile(files[0], 'sectionSelectionBackground')}
            >
              <Typography>{ !backgroundVideo
                ? t('edit.poi.dragVideo')
                : backgroundVideo.fileName}
              </Typography>
            </FileDragDrop>
          </>}

          <Box mr={2}>
            <InputLabel id="background-label">{t('edit.mode.nuggetBackground')}</InputLabel>
            <Controller
              control={methods.control}
              name="config.nuggetBackgroundType"
              render={({ field: { onChange, value } }) => <Select
                variant='standard'
                labelId="nugget-background-label"
                sx={{ width: '200px' }}
                value={value}
                defaultValue="default"
                label={t('common.background')}
                onChange={event => onChange(event.target.value)}
              >
                <MenuItem value="default">{t('common.default')}</MenuItem>
                <MenuItem value="image">{t('common.image')}</MenuItem>
                <MenuItem value="video">{t('common.video')}</MenuItem>
              </Select>
            }
            />

          </Box>
          {nuggetBackgroundType === 'image' && <FileDragDrop
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
            minHeight="200px"
            preview
            limit={1}
            onFilesChanged={(files) => addFile(files[0], 'nuggetBackground')}
          >
            {!nuggetBackgroundImage
              ? <Typography>{t('edit.poi.dragImage')}</Typography>
              : <Box width="100%"
                  height="100%"
                  sx={{
                    backgroundImage: `url(${nuggetBackgroundImage.url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center'
                  }}
              />}
          </FileDragDrop>}
          {nuggetBackgroundType === 'video' && <>
            <FileDragDrop
              accept={{ 'video/*': ['.mp4'] }}
              limit={1}
              preview
              style={{ marginBottom: '32px' }}
              onFilesChanged={(files) => addFile(files[0], 'nuggetBackground')}
            >
              <Typography>{ !nuggetBackgroundVideo
                ? t('edit.poi.dragVideo')
                : nuggetBackgroundVideo.fileName}
              </Typography>
            </FileDragDrop>
          </>}
          <Box mr={2}>
            <InputLabel id="section-order-label">{t('edit.mode.sectionOrderMarker')}</InputLabel>
            <Controller
              control={methods.control}
              name="config.sectionOrderMarkerType"
              render={({ field: { onChange, value } }) => <Select
                variant='standard'
                labelId="section-order-label"
                sx={{ width: '200px', marginBottom: 4 }}
                value={value}
                defaultValue="default"
                label={t('edit.mode.sectionOrderMarker')}
                onChange={event => onChange(event.target.value)}
              >
                <MenuItem value="default">{t('common.default')}</MenuItem>
                <MenuItem value="custom">{t('common.custom')}</MenuItem>
              </Select>
            }
            />
            {sectionOrderMarkerType === 'custom' && <>
              <FileDragDrop
                accept={{ 'image/*': ['.svg'] }}
                height="100%"
                limit={1}
                preview
                style={{ marginBottom: '32px' }}
                onFilesChanged={(files) => addFile(files[0], 'sectionOrderMarkerBackground')}
              >
                <Typography>{ !sectionOrderMakerImage
                  ? t('edit.poi.dragImage')
                  : sectionOrderMakerImage.fileName}
                </Typography>
              </FileDragDrop>
            </>}
          </Box>
        </Box>
      </Box>

      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={isUpdating || isCreating}
        uploadError={uploadError}
        showSnackbar={showSnackbar}
        closeSnackbar={() => setShowSnackbar(false)}
      >
        <Button
          color="warning"
          variant="outlined"
          endIcon={<DeleteIcon />}
          onClick={() => setShowConfirmDialog(true)}
        >
          {t('edit.mode.delete')}
        </Button>

        <Box flex={100} />

        <Tooltip title={ t('edit.mode.publishUpdateTooltip')}>
          <span>
            <LoadingButton
              color="success"
              variant="outlined"
              disabled={canUpdateTourMode?.canUpdateTourMode === false}
              loading={updateCheckLoading || isPublishing}
              sx={{ mr: 1 }}
              endIcon={<UpdateIcon />}
              onClick={publishUpdate}
            >
              {t('edit.mode.publishUpdate')}
            </LoadingButton>
          </span>
        </Tooltip>
      </FormFooterBar>
    </form>

    <ConfirmDialog
      open={showConfirmDialog}
      text={t('edit.mode.confirmDelete')}
      onConfirm={onConfirmDelete}
      onCancel={() => setShowConfirmDialog(false)}
    />

    <ConfirmDialog
      open={showPublishInfoDialog}
      text={t('edit.mode.publishInfo', publishInfo)}
      onConfirm={() => setShowPublishInfoDialog(false)}
    />
  </FormProvider>
}

export default ModeEdit
