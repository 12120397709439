import { apolloClient } from '@services/apollo'
import {
  DeleteFileDocument,
  DeleteFileMutationFn,
  DeleteFileMutationVariables,
  FileDataFragment,
  ReplaceFileInOrderDocument,
  ReplaceFileInOrderMutationFn,
  UpdateContentMutationFn,
  UpdateFileDocument,
  UpdateFileMutationVariables,
  UploadFileDocument,
  UploadFileMutationFn,
  UploadFileMutationVariables
} from '@typings/graphql'

export type ContentFile = FileDataFragment

export const isGraphQlFile = (file: ContentFile | File | undefined | null): file is ContentFile => {
  return !!(file as ContentFile)?.id
}

export const uploadFile = async (variables: UploadFileMutationVariables) => {
  return new Promise((resolve, reject) => {
    apolloClient.mutate<UploadFileMutationFn, UploadFileMutationVariables>({
      mutation: UploadFileDocument,
      variables
    }).then(resolve).catch(reject)
  })
}

export const uploadContentFiles = async (variables: UploadFileMutationVariables[]) => {
  const imageUploads = variables.map((vars) => (
    new Promise((resolve, reject) => {
      apolloClient.mutate<UploadFileMutationFn, UploadFileMutationVariables>({
        mutation: UploadFileDocument,
        variables: vars
      }).then(resolve).catch(reject)
    })
  ))

  return await Promise.all(imageUploads).then((values) => values).catch((e) => e)
}

export const replaceContentFiles = async (variables: UploadFileMutationVariables[]) => {
  const imageUploads = variables.map((vars) => (
    new Promise((resolve, reject) => {
      apolloClient.mutate<ReplaceFileInOrderMutationFn, UploadFileMutationVariables>({
        mutation: ReplaceFileInOrderDocument,
        variables: vars
      }).then(resolve).catch(reject)
    })
  ))

  return await Promise.all(imageUploads).then((values) => values).catch((e) => e)
}

export const updateContentFiles = async (variables: UpdateFileMutationVariables[]) => {
  const deleteImages = variables.map((vars) => (
    new Promise((resolve, reject) => {
      apolloClient.mutate<UpdateContentMutationFn, UpdateFileMutationVariables>({
        mutation: UpdateFileDocument,
        variables: vars
      }).then(resolve).catch(reject)
    })
  ))

  return await Promise.all(deleteImages).then((values) => values).catch((e) => e)
}

export const deleteContentFiles = async (variables: DeleteFileMutationVariables[]) => {
  const deleteImages = variables.map((vars) => (
    new Promise((resolve, reject) => {
      apolloClient.mutate<DeleteFileMutationFn, DeleteFileMutationVariables>({
        mutation: DeleteFileDocument,
        variables: vars
      }).then(resolve).catch(reject)
    })
  ))

  return await Promise.all(deleteImages).then((values) => values).catch((e) => e)
}
