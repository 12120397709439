import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { Box, Checkbox, Chip, Collapse, FormControlLabel, TextField, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ContentDataFragment } from '@typings/graphql'

type Props = {
  content: ContentDataFragment
}

export const ContentBlockEditor: React.FC<Props> = ({ content }) => {
  const [shouldBlock, setShouldBlock] = useState(!!content.blockedById)

  const { t } = useTranslation()
  const methods = useFormContext()

  return <Box>
    <Box mb={2}>
      <Chip
        label={t('edit.content.contentBlocker')}
        size="small"
      />

      <FormControlLabel
        sx={{ ml: '12px' }}
        control={<Checkbox
          checked={shouldBlock}
          onChange={(e) => setShouldBlock(e.target.checked)}
        />}
        label={<Typography fontSize={12}>{t('common.activate')}</Typography>}
      />
    </Box>

    <Collapse in={shouldBlock}>
      <TextField
        sx={{ width: '100%' }}
        label={t('edit.content.blockedBy')}
        {...methods.register('blockedById', {
          required: shouldBlock
        })}
        InputLabelProps={{ shrink: true }}
      />

      <Box mt={2} mb={1} display="flex" alignItems="center">
        <InfoIcon fontSize="small" />
        <Typography sx={{ ml: 0.5, opacity: 0.7, fontStyle: 'italic', fontSize: '14px' }}>
          {t('edit.content.blockedContentText')}
        </Typography>
      </Box>
      <TextField sx={{ width: '100%' }} {...methods.register('texts.blocked')} />
    </Collapse>
  </Box>
}
