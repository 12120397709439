import React, {
  ForwardRefRenderFunction, PropsWithChildren, useCallback, useImperativeHandle, useMemo, useState
} from 'react'
import { FileError, FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Box, BoxProps, Button, CircularProgress, Fade, Snackbar, Typography
} from '@mui/material'

import { FilePreview } from './FilePreview'
import { ContentFile } from './content-type-editors/mutation-helper/file'

export type FileFormats = {
  'image/*'?: string[],
  'video/*'?: string[],
  'audio/*'?: string[],
  'text/html'?: string[],
  'text/plain'?: string[],
  'text/csv'?: string[],
  'application/json'?: string[],
  'application/zip'?: string[]
  'application/object'?: string[],
}

export type FileDragDropProps = PropsWithChildren & BoxProps & {
  preview?: boolean
  accept: FileFormats
  onFilesChanged: (files: File[]) => void
  dragPlaceholder?: string
  dropPlaceholder?: string
  initialFile?: ContentFile
  loading?: boolean
  limit?: number
  maxFileSize?: number
};

export type FileDragDropHandler = {
  open: () => void,
  reset: () => void
}

type FileDragDropRenderFn = ForwardRefRenderFunction<FileDragDropHandler, FileDragDropProps>

const FileDragDrop: FileDragDropRenderFn = (props, ref) => {
  const {
    onFilesChanged,
    accept,
    dragPlaceholder,
    dropPlaceholder,
    loading,
    initialFile,
    preview,
    limit,
    maxFileSize = 20000000,
    children,
    ...boxProps
  } = props
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false)
  const [errorMessages, setErrorMessages] = React.useState<FileError[]>()
  const [limitError, setLimitError] = React.useState<string | undefined>(undefined)
  const { t } = useTranslation()
  const [files, setFiles] = useState<File[]>()

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles?: FileRejection[]) => {
    if (rejectedFiles) {
      rejectedFiles.forEach(({ errors }) => {
        console.log(errors)
        setErrorMessages(errors)
        setSnackbarOpen(true)
      })
    }

    if (limit && acceptedFiles.length > limit) {
      setLimitError(t('errors.fileLimitError'))
      setSnackbarOpen(true)
      return
    }
    setFiles(acceptedFiles)
    onFilesChanged(acceptedFiles)
  }, [])

  const filesPreview = useMemo(() => {
    if (!preview || (!files?.length && !initialFile)) {
      return null
    }

    if (files?.length) {
      return files.map((file: File) => ({
        url: URL.createObjectURL(file),
        fileName: file.name,
        type: file.type, // Object.keys(accept)[0]
        fileSize: file.size
      }))
    }

    if (!initialFile) {
      return null
    }

    return [{
      url: initialFile.url,
      fileName: initialFile.fileName,
      type: initialFile.mimeType,
      fileSize: initialFile.fileSize
    }]
  }, [files])

  const {
    getRootProps, getInputProps, isDragActive, open
  } = useDropzone({
    accept,
    maxSize: maxFileSize,
    multiple: false,
    onDrop
  })

  useImperativeHandle(ref, () => ({
    open,
    reset: () => onDrop([])
  }))

  return (
    <Box display="flex" justifyContent="center" {...getRootProps()} {...boxProps} position="relative" overflow="hidden">
      <Button
        variant="text"
        sx={{
          border: 'dashed 1px',
          borderColor: 'grey.500',
          height: 'auto',
          maxHeight: '60vh',
          width: '100%',
          '&:hover': {
            borderColor: 'grey.700'
          },
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center'
        }}
      >
        { filesPreview
          ? <Box width="100%" height="100%" p={1}><FilePreview preview={filesPreview} /></Box>
          : children }
      </Button>
      <Box position="relative">
        <input {...getInputProps()} />
        {
        isDragActive
          ? dropPlaceholder && <Typography>{dropPlaceholder}</Typography>
          : dragPlaceholder && <Typography>{dragPlaceholder}</Typography>
      }
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setSnackbarOpen(false)
        }}
      >
        <Alert severity="error">
          {errorMessages && <Typography>{t('errors.invalidFileType')}</Typography> ||
            <Typography>{limitError}</Typography> }
        </Alert>
      </Snackbar>

      <Fade in={loading}>
        <Box
          position="absolute"
          left={-10}
          top={-10}
          right={-10}
          bottom={-10}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ backdropFilter: 'blur(5px)' }}
        >
          <CircularProgress />
        </Box>
      </Fade>
    </Box>
  )
}

export default React.forwardRef(FileDragDrop)
