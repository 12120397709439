import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { Box, Button, Chip, IconButton, Stack, TextField, Typography } from '@mui/material'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { useTranslation } from 'react-i18next'
import { TranslatableTextInput, UpdateContentInput, UploadFileMutationVariables } from '@typings/graphql'
import { convertToTextObject } from '@utils/texts'
import cuid from 'cuid'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { ContentFile, deleteContentFiles, replaceContentFiles, uploadContentFiles } from '../mutation-helper/file'
import { deleteTexts } from '../mutation-helper/text'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { MediaInfos } from '../components/MediaInfos'
import { carouselConfigSchema } from '../config-schemas/default/carousel'

import { ContentEditorComponentProps } from '.'

type UpdateCarouselContentFormInput = {
  files: {
    file: ContentFile | File | null,
    replaceId: string | null,
    key: string,
    text: string
  }[],
  blockedById: string | null,
  style: string,
  config: string
}

type FileIdAndKey = {
  id: string,
  key: string
}

export const CarouselContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown | undefined>(undefined)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [deletedFileIdsAndKeys, setDeletedFileIdsAndKeys] = useState<FileIdAndKey[]>([])

  const getDefaultValues = () => {
    return {
      files: [...content.files].map((file) => ({
        file,
        replaceId: null,
        key: file.key,
        text: content.texts[file.key] || ''
      })),
      blockedById: content.blockedById || null,
      style: JSON.stringify(content.style, null, '\t') || '',
      config: JSON.stringify(content.config, null, '\t') || ''
    }
  }

  const methods = useForm<UpdateCarouselContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: 'files'
  })

  const replaceFile = (newFile: File, oldFile: ContentFile, index: number) => {
    methods.setValue(`files.${index}.file`, newFile, { shouldDirty: true })

    if (oldFile) methods.setValue(`files.${index}.replaceId`, oldFile.id)
  }

  const deleteFile = (index: number, fileId: string, key: string) => {
    remove(index)

    if (fileId) {
      setDeletedFileIdsAndKeys((prev) => [...prev, {
        id: fileId,
        key
      }])
    }
  }

  const addFileItem = () => {
    append({
      file: null,
      replaceId: null,
      key: cuid(),
      text: ''
    })
  }

  const fileEditItems = useMemo(() => (
    fields.map((field, index) => {
      const { file, id } = field

      return (
        <Box
          key={id}
          height="150px"
          display="flex"
          alignItems="flex-start"
          padding="16px"
          border="1px solid #CFD7DD"
          borderTop={index === 0 ? undefined : 'none'}
        >
          <FileDragDrop
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
            height="100%"
            width="30%"
            limit={1}
            preview
            onFilesChanged={(files) => replaceFile(files[0], file as ContentFile, index)}
          >
            {(file as any)?.url && <><Box width="100%"
              height="100%"
              sx={{
                backgroundImage: `url(${(file as ContentFile).url})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center center'
              }}
            />
              <MediaInfos file={file as ContentFile}></MediaInfos>
            </>}
          </FileDragDrop>
          <Controller
            control={methods.control}
            name={`files.${index}.text`}
            rules={{ validate: () => methods.getValues(`files.${index}.file`) !== null }}
            render={({ field: { onChange, value }, fieldState: { error: e } }) =>
              <TextField
                value={value}
                onChange={onChange}
                error={!!e}
                sx={{ marginBottom: '32px', marginLeft: '18px', width: '500px' }}
                helperText={!!e && t('edit.content.fileRequired')}
                label={t('common.description')}
              />}
          />
          <Box padding="10px" height="56px">
            <IconButton onClick={() => deleteFile(index, (file as any)?.id, (file as any)?.key)}>
              <DeleteIcon></DeleteIcon>
            </IconButton>
          </Box>
        </Box>
      )
    })
  ), [fields])

  const onSubmit = async (submittedData: UpdateCarouselContentFormInput) => {
    setLoading(true)

    const carouselFiles = submittedData.files.filter((file) => file.file !== null)

    const textUpdateVariables: TranslatableTextInput[] = []

    carouselFiles.forEach((fileItem) => {
      if (!content.texts[fileItem.key] || fileItem.text !== content.texts[fileItem.key]) {
        textUpdateVariables.push(convertToTextObject(fileItem.key, fileItem.text))
      }
    })

    const newFilesUploadVariables = carouselFiles.reduce((acc, curr, index) => {
      if (!(curr.file as any)?.id && !curr.replaceId) {
        textUpdateVariables.push(convertToTextObject(curr.key, curr.text))

        acc.push({
          file: curr.file,
          data: {
            key: curr.key,
            order: index,
            model: 'Content',
            modelId: content.id
          }
        })
      }

      return acc
    }, [] as UploadFileMutationVariables[])

    if (newFilesUploadVariables.length) {
      try {
        await uploadContentFiles(newFilesUploadVariables)
      } catch (e) {
        setError(e)
      }
    }

    const replaceFileVariables = carouselFiles.reduce((acc, curr, index) => {
      if (curr.replaceId) {
        acc.push({
          file: curr.file,
          data: {
            key: curr.key,
            replaceId: curr.replaceId,
            order: index,
            model: 'Content',
            modelId: content.id
          }
        })
      }

      return acc
    }, [] as UploadFileMutationVariables[])

    if (replaceFileVariables.length) {
      try {
        await replaceContentFiles(replaceFileVariables)
      } catch (e) {
        setError(e)
      }
    }

    if (deletedFileIdsAndKeys.length) {
      const deletedFileIds = deletedFileIdsAndKeys.map((item) => ({ id: item.id }))
      const deletedTextsVariables = deletedFileIdsAndKeys.map((item) => ({
        key: item.key,
        model: 'Content',
        modelId: content.id
      }))

      try {
        await deleteContentFiles(deletedFileIds)
        await deleteTexts(deletedTextsVariables)
      } catch (e) {
        setError(e)
      }
    }

    const data: UpdateContentInput = {
      nuggetId,
      order: content.order,
      type: content.type,
      blockedById: submittedData.blockedById || null,
      config: JSON.parse(submittedData.config),
      style: JSON.parse(submittedData.style),
      texts: textUpdateVariables
    }

    try {
      await updateContent(content.id, data)

      methods.reset()
    } catch (e) {
      setError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch?.()
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
    setError(undefined)
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <Box>
          <Chip label="config" size="small" sx={{ marginBottom: '12px' }} />
          {fileEditItems}
        </Box>
        <Box width="600xpx">
          <Button variant="outlined" onClick={addFileItem}>
            <Typography sx={{ marginRight: 1 }}>{t('edit.content.addFile')}</Typography>
            <AddCircleOutlineIcon></AddCircleOutlineIcon>
          </Button>
        </Box>
        <ConfigAndStyleEditor content={content} schema={carouselConfigSchema} />
        <ContentBlockEditor content={content} />
      </Stack>
      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={error}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
