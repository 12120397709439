
import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { Box, Button, Chip, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { ConfigEditor } from '../components/ConfigEditor'
import { ContentFile, isGraphQlFile, uploadFile } from '../mutation-helper/file'
import { voiceoverConfigSchema } from '../config-schemas/lgs-kids/voiceover'

import { VoiceoverMarkerSelection } from './components/VoiceoverMarkerSelection'

import type { ContentEditorComponentProps } from '.'

type UpdateVoiceoverContentFormInput = {
  backgroundImage?: ContentFile | File | null;
  texts: { key: string, text: string, button?: string }[]
  config: Record<string, any>
}

export const VoiceoverContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const defaultValues = useMemo<UpdateVoiceoverContentFormInput>(() => {
    const voiceOverTexts = Object.keys(content.texts).filter((key) => key.startsWith('text_'))
    const keysSorted = voiceOverTexts.map((key) => parseInt(key.split('_')[1]))
    keysSorted.sort((a, b) => a - b)

    return {
      backgroundImage: content.files.find((file) => file.key === 'background'),
      config: content.config ?? {},
      texts: keysSorted.length
        ? keysSorted.map((key) => {
          return { key: `text_${key}`, text: content.texts[`text_${key}`], button: content.texts[`button_${key}`] ?? '' }
        })
        : [{ key: 'text_1', text: '' }]
    }
  }, [content])

  const methods = useForm<UpdateVoiceoverContentFormInput>({
    defaultValues,
    mode: 'onChange'
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'texts'
  })

  const appendText = () => {
    const texts = methods.getValues('texts')
    const lastIndex = texts.map((text) => parseInt(text.key.split('_')[1])).sort().pop() || 0

    append({ key: `text_${lastIndex + 1}`, text: '', button: '' })
  }

  const canSave = useMemo(() => methods.formState.isDirty, [methods.formState])

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const onSubmit = methods.handleSubmit(async (submittedData) => {
    setLoading(true)

    const translatableTexts = submittedData.texts.reduce((acc, text) => {
      if (text.text) {
        acc[text.key] = text.text
      }
      if (text.button) {
        acc[`button_${text.key.split('_')[1]}`] = text.button
      }
      return acc
    }, {} as Record<string, string>)

    const texts = await syncContentTexts(content, translatableTexts)

    if (submittedData.backgroundImage && !isGraphQlFile(submittedData.backgroundImage)) {
      await uploadFile({
        data: {
          key: 'background',
          model: 'Content',
          modelId: content.id,
          replace: true
        },
        file: submittedData.backgroundImage
      })
    }

    try {
      await updateContent(content.id, {
        nuggetId,
        texts,
        order: content.order,
        config: submittedData.config
      })
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  })

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  const textFields = useMemo(() => {
    const fieldsSorted = [...fields]
    fieldsSorted.sort((a, b) => a.key.localeCompare(b.key))

    return fieldsSorted.map((field, index) => (
      <Box key={field.id}>
        <Typography fontSize="small" mb={1}>{field.key}</Typography>
        <Box display="flex" gap={4} alignItems="center">
          <MarkdownEditor
            name={`texts.${index}.text`}
            height={150}
            style={{ flex: 1 }}
          />
          <Box display="flex" flexDirection="column" width="300px" justifyContent="space-between" alignSelf="stretch">
            <VoiceoverMarkerSelection name={`config.voiceoverMarker.${field.key}`} />
            <TextField {...methods.register(`texts.${index}.button`)} sx={{ width: '100%' }} label={t('common.buttonText')} />
          </Box>
          <IconButton
            sx={{ ml: 2, alignSelf: 'center' }}
            onClick={() => remove(index)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      </Box>
    ))
  }, [fields])

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={onSubmit}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <Box>
            <Chip label={t('common.texts')} size="small" sx={{ marginBottom: '8px' }} />

            <Stack spacing={2}>
              {textFields}

              <Button
                variant="outlined"
                sx={{ alignSelf: 'flex-start' }}
                onClick={appendText}
              >
                <AddCircleOutlineIcon />
              </Button>
            </Stack>
          </Box>

          <Box display="flex" flexDirection="row" gap={2}>
            <Box flex={2}>
              <ConfigEditor schema={voiceoverConfigSchema}/>
            </Box>
            <Box flex={1}>
              <Chip label={t('edit.content.backgroundImage')} size="small" sx={{ marginBottom: '8px' }} />
              <Controller
                name="backgroundImage"
                control={methods.control}
                render={({ field: { value, onChange } }) => (
                  <FileDragDrop
                    initialFile={isGraphQlFile(value) ? value : undefined}
                    onFilesChanged={(files) => onChange(files[0])}
                    preview
                    accept={{ 'image/*': ['.png', '.jpeg', '.jpg', '.webp'] }}
                    sx={{ width: '100%', height: 200 }}
                  />
                )}
              />
            </Box>
          </Box>
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading}
          uploadError={ uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
