
export const voiceoverConfigSchema = {
  type: 'object',
  properties: {
    animation: {
      type: 'array',
      items: [
        {
          type: 'number'
        },
        {
          type: 'number'
        }
      ]
    },
    voiceoverMarker: {
      type: 'object'
    },
    textAnchor: {
      type: 'string',
      enum: ['top', 'bottom']
    },
    textPosition: {
      type: ['string', 'number']
    },
    arrowPositionX: {
      type: ['string', 'number'],
      enum: ['left', 'center', 'right']
    },
    arrowPositionY: {
      type: 'string',
      enum: ['top', 'bottom']
    }
  },
  required: ['animation', 'voiceoverMarker', 'textAnchor', 'textPosition', 'arrowPositionX', 'arrowPositionY']
}
