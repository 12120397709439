import { MuiIconNames } from '@features/cms/typings/material-icon-font'

export const arConfigSchema = {
  type: 'object',
  properties: {
    type: { type: 'string', enum: ['marker-model', 'marker-image', 'plane-model'] },
    icon: {
      type: 'string',
      enum: MuiIconNames
    },
    buttonProps: {
      type: 'object'
    }
  },
  required: ['type']
}
