import { UpdateContentInput, useUploadFileMutation } from '@typings/graphql'
import React, { useState, useMemo, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { Stack, Box, Chip, Typography } from '@mui/material'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { ConfigEditor } from '../components/ConfigEditor'
import { ContentBlockEditor } from '../components/ContentBlockEditor'
import { MediaInfos } from '../components/MediaInfos'
import { audioPlayerConfigSchema } from '../config-schemas/default/audioPlayer'

import { ContentEditorComponentProps } from '.'

type UpdateAudioPlayerContentFormInput = {
  blockedById: string | null,
  config: string,
}

export const AudioPlayerContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [voiceoverFile, setVoiceoverFile] = useState<File>()

  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [canSave, setCanSave] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const [uploadFile, { loading: isUploading, error }] = useUploadFileMutation()

  const defaultValues = useMemo(() => {
    return {
      blockedById: content.blockedById || null,
      config: JSON.stringify(Object.keys(content.config).length
        ? content.config
        : {
            variant: 'button'
          }, null, '\t')
    }
  }, [content])

  const methods = useForm<UpdateAudioPlayerContentFormInput>({
    defaultValues
  })

  useEffect(() => {
    setCanSave(!!voiceoverFile || methods.formState.isDirty)
    onEdited?.(!!voiceoverFile || methods.formState.isDirty)
  }, [methods.formState.isDirty, voiceoverFile])

  useUnsavedChangesAlert(methods.formState.isDirty)

  const addFile = (files: File[]) => setVoiceoverFile(files[0])

  const onSubmit = async (submittedData: UpdateAudioPlayerContentFormInput) => {
    if (voiceoverFile) {
      await uploadFile({
        variables: {
          file: voiceoverFile,
          data: {
            key: 'voiceover',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        }
      })

      setVoiceoverFile(undefined)
    }

    if (methods.formState.isDirty) {
      setLoading(true)

      const data: UpdateContentInput = {
        nuggetId,
        texts: [],
        order: content.order,
        type: content.type,
        blockedById: submittedData.blockedById || null,
        config: JSON.parse(submittedData.config)
      }

      try {
        await updateContent(content.id, data)
      } catch (e) {
        setUploadError(e)
      }
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch?.()
    methods.reset()
  }

  useEffect(() => {
    methods.reset(defaultValues)
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
    methods.reset()
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <Box>
            <Chip label={content.files[0]?.key || 'voiceover'} size="small" sx={{ marginBottom: '8px' }} />
            <FileDragDrop
              accept={{ 'audio/*': ['.mp3', '.mp4', '.acc'] }}
              preview
              limit={1}
              onFilesChanged={(files) => addFile(files)}
            >
              {!content?.files.length
                ? <Typography>{t('edit.poi.dragAudio')}</Typography>
                : <><Typography>{content.files[0].fileName}</Typography>
                  <MediaInfos file={content.files[0]}></MediaInfos>
                </>}
            </FileDragDrop>
          </Box>

          <ConfigEditor schema={audioPlayerConfigSchema} />
          <ContentBlockEditor content={content} />
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading || isUploading}
          uploadError={error || uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
