import FileDragDrop from '@features/cms/components/FileDragDrop'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { Box, Button, Checkbox, Chip, IconButton, TextField, Typography } from '@mui/material'
import { FileDataFragmentDoc, UpdateContentInput, useUploadFileMutation } from '@typings/graphql'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { updateContent } from '../mutation-helper/content'
import { syncContentTexts } from '../mutation-helper/text'
import { ContentFile, isGraphQlFile } from '../mutation-helper/file'
import { ConfigAndStyleEditor } from '../components/ConfigAndStyleEditor'
import { InteractionCardEditor, InteractionCardFormInputs, interactionCardTextKeys } from '../components/InteractionCardEditor'
import { swipeCardsTestConfigSchema } from '../config-schemas/default/swipeCardsTest'

import type { ContentEditorComponentProps } from '.'

type UpdateSwipeContentFormInput = InteractionCardFormInputs & {
  texts: {
    title: string,
    exercise: string,
    textLeft: string,
    textRight: string,
    solution: string,
  },
  items: SwipeItem[],
  config: Record<string, any>,
  style: Record<string, any>,
}

type SwipeItem = {
  key: string
  text: string
  correct: boolean
  file?: ContentFile | File | null
}

export const SwipeCardsTestContentEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const [uploadFile, { loading: isUploading, error }] = useUploadFileMutation()

  const defaultValues = useMemo<UpdateSwipeContentFormInput>(() => {
    const itemTextKeys = Object.keys(content.texts).filter((key) => key.startsWith('item_'))
    itemTextKeys.sort((a, b) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1]))

    return {
      interactionCardTexts: interactionCardTextKeys.map((key) => ({
        key,
        value: content.texts[key] || ''
      })),
      descriptionImage: {
        key: 'description',
        file: content.files.find((file: ContentFile) => file.key === 'description') || null,
        replaceId: null
      },
      texts: content.texts,
      config: content.config ?? {},
      style: content.style ?? {},
      items: itemTextKeys.length
        ? itemTextKeys.map((key) => ({
          key,
          text: content.texts[key],
          correct: content.config?.correct?.includes(parseInt(key.split('_')[1])),
          file: content.files.find((file: ContentFile) => file.key === key)
        }))
        : [{ key: 'item_1', text: '', correct: false }]
    }
  }, [content])

  const methods = useForm<UpdateSwipeContentFormInput>({
    defaultValues
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'items'
  })

  const appendItem = () => {
    const items = methods.getValues('items')
    const indices = items.map((item) => parseInt(item.key.split('_')[1])).sort((a, b) => (a - b))
    const lastIndex = indices.length ? indices.pop() as number : 0

    append({ key: `item_${lastIndex + 1}`, text: '', correct: false })
  }

  const canSave = useMemo(() => methods.formState.isValid && methods.formState.isDirty, [methods.formState.isDirty, methods.formState.isValid])

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const onSubmit = async (submittedData: UpdateSwipeContentFormInput) => {
    setLoading(true)

    if (submittedData.descriptionImage?.file && !(submittedData.descriptionImage.file as any).id) {
      try {
        await uploadFile({
          variables: {
            file: submittedData.descriptionImage.file,
            data: {
              key: 'description',
              replace: true,
              model: 'Content',
              modelId: content.id
            }
          }
        })
      } catch (e) {
        setUploadError(e)
      }
    }

    const translatableTexts = {
      ...submittedData.texts,
      ...submittedData.interactionCardTexts.reduce((acc, item) => {
        acc[item.key] = item.value
        return acc
      }, {} as Record<string, string>),
      ...submittedData.items.reduce((acc, item) => {
        acc[item.key] = item.text
        return acc
      }, {} as Record<string, string>)
    }

    const texts = await syncContentTexts(content, translatableTexts)

    const files = submittedData.items
      .filter((item) => item.file && !(item.file as any).id)
      .map((item) => ({
        key: item.key,
        file: item.file as File
      }))

    if (files.length) {
      try {
        await Promise.all(files.map(async ({ key, file }) => {
          await uploadFile({
            variables: {
              file,
              data: {
                key,
                model: 'Content',
                modelId: content.id,
                replace: true
              }
            },
            update: (cache, { data }) => {
              cache.modify({
                id: cache.identify({
                  __typename: 'Content',
                  id: content.id
                }),
                fields: {
                  files (existingFiles = []) {
                    const newFileRef = cache.writeFragment({
                      data: data?.uploadFile,
                      fragment: FileDataFragmentDoc,
                      fragmentName: 'FileData'
                    })

                    return [...existingFiles, newFileRef]
                  }
                }
              })
            }
          })
        }))
      } catch (e) {
        setUploadError(e)
      }
    }

    const data: UpdateContentInput = {
      nuggetId,
      texts,
      order: content.order,
      config: {
        ...submittedData.config,
        correct: submittedData.items
          .filter((item) => item.correct)
          .map((item) => parseInt(item.key.split('_')[1]))
      },
      style: submittedData.style
    }

    try {
      await updateContent(content.id, data)
    } catch (e) {
      setUploadError(e)
    }

    setLoading(false)
    setShowSnackbar(true)
  }

  const closeSnackbar = () => {
    setShowSnackbar(false)
  }

  const textFields = useMemo(() => {
    const fieldsSorted = [...fields]
    fieldsSorted.sort((a, b) => parseInt(a.key.split('_')[1]) - parseInt(b.key.split('_')[1]))

    return fieldsSorted.map((field, index) => (
      <Box key={field.id} display="flex" alignItems="center" gap={2}>
        <Typography fontSize="small" sx={{ opacity: 0.7 }} width={12}>{field.key.split('_')[1]}</Typography>

        <Controller name={`items.${index}.correct`}
          control={methods.control}
          render={({ field: { value, onChange } }) => <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
          }
        />
        <TextField {...methods.register(`items.${index}.text`, { required: true })} />
        <Controller name={`items.${index}.file`}
          control={methods.control}
          render={({ field: { value, onChange } }) => {
            return <FileDragDrop
              accept={{ 'image/*': ['.png', '.webp', '.jpg', '.jpeg'] }}
              alignItems="stretch"
              alignSelf="unset"
              width={120}
              maxHeight={60}
              preview
              initialFile={isGraphQlFile(value) ? value : undefined}
              onFilesChanged={([file]) => onChange(file)}
            >
              <Typography>{ !value ? t('edit.poi.dragImage') : (value as any).fileName }</Typography>
            </FileDragDrop>
          }}
        />
        <IconButton
          sx={{ ml: 2, alignSelf: 'center' }}
          onClick={() => remove(index)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
    ))
  }, [fields])

  return (
    <FormProvider {...methods}>
      <form
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Stack spacing={6} p={4} flex={1} sx={{ overflowY: 'auto' }}>
          <Box>
            <InteractionCardEditor content={content} />
          </Box>

          <Box>
            <Stack spacing={2}>
              <TextField
                {...methods.register('texts.title', { required: true })}
                sx={{ width: '500px' }}
                label={t('common.title')}
              />
              <TextField
                {...methods.register('texts.exercise', { required: true })}
                sx={{ width: '500px' }}
                label={t('common.exercise')}
              />
              <TextField
                {...methods.register('texts.solution', { required: true })}
                sx={{ width: '500px' }}
                label={t('common.solution')}
              />
            </Stack>
          </Box>
          <Box>
            <Chip label={t('common.texts')} size="small" />
            <Stack spacing={2} marginBottom={8} marginTop={4}>
              <TextField
                {...methods.register('texts.textLeft')}
                sx={{ width: '200px' }}
                label={t('edit.content.textLeft')}
              />
              <TextField
                {...methods.register('texts.textRight')}
                sx={{ width: '200px' }}
                label={t('edit.content.textRight')}
              />
            </Stack>

            <Stack spacing={2}>
              {textFields}

              <Button
                variant="outlined"
                sx={{ alignSelf: 'flex-start' }}
                onClick={appendItem}
              >
                <AddCircleOutlineIcon />
              </Button>
            </Stack>
          </Box>
          <Box>
            <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
              <ConfigAndStyleEditor content={content} schema={swipeCardsTestConfigSchema} />
            </Stack>
          </Box>
        </Stack>

        <FormFooterBar
          disabled={!canSave}
          loading={loading || isUploading}
          uploadError={error || uploadError}
          showSnackbar={showSnackbar}
          closeSnackbar={closeSnackbar}
        />
      </form>
    </FormProvider>)
}
