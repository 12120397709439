import React, { useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Box, Chip, Stack, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { convertToTextObject } from '@utils/texts'
import { UpdateContentInput } from '@typings/graphql'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

import { EditableFile } from '../components/InteractionCardEditor'
import { ContentFile, uploadFile } from '../mutation-helper/file'
import { deleteTexts } from '../mutation-helper/text'
import { updateContent } from '../mutation-helper/content'
import { ContentBlockEditor } from '../components/ContentBlockEditor'

import { ContentEditorComponentProps } from '.'

type LiveInteractionCardContentFormInput = {
  title: string
  description: string
  image: EditableFile,
  voiceover: EditableFile,
  iconImage: EditableFile,
  interactionButtonText: string,
  blockedById: string | null,
}

const imageKeys = ['image', 'iconImage'] as const
const textKeys = ['title', 'description', 'interactionButtonText'] as const

export const LiveInteractionCardEditor: React.FC<ContentEditorComponentProps> = ({ nuggetId, content, refetch, onEdited }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [uploadError, setUploadError] = useState<any>(null)

  const getDefaultValues = (): LiveInteractionCardContentFormInput => ({
    title: content.texts.title,
    description: content.texts.description,
    image: {
      key: 'image',
      file: content.files.find(file => file.key === 'image') || null,
      replaceId: null
    },
    voiceover: {
      key: 'voiceover',
      file: content.files.find(file => file.key === 'voiceover') || null,
      replaceId: null
    },
    iconImage: {
      key: 'iconImage',
      file: content.files.find(file => file.key === 'iconImage') || null,
      replaceId: null
    },
    interactionButtonText: content.texts.interactionButtonText,
    blockedById: content.blockedById || null
  })

  const methods = useForm<LiveInteractionCardContentFormInput>({
    defaultValues: getDefaultValues()
  })

  useUnsavedChangesAlert(methods.formState.isDirty)

  useEffect(() => {
    onEdited?.(methods.formState.isDirty)
  }, [methods.formState.isDirty])

  const image = useWatch({
    control: methods.control,
    name: 'image'
  })

  const iconImage = useWatch({
    control: methods.control,
    name: 'iconImage'
  })

  const voiceover = useWatch({
    control: methods.control,
    name: 'voiceover'
  })

  const addImage = (file: File, key: typeof imageKeys[number], oldFile?: ContentFile) => {
    methods.setValue(`${key}.file`, file, { shouldDirty: true })

    if (oldFile) methods.setValue(`${key}.replaceId`, oldFile.id)
  }

  const addVoiceover = (file: File, key: 'voiceover', oldFile?: ContentFile) => {
    methods.setValue(`${key}.file`, file, { shouldDirty: true })

    if (oldFile) methods.setValue(`${key}.replaceId`, oldFile.id)
  }

  const onSubmit = methods.handleSubmit(async (submittedData) => {
    setLoading(true)

    const translatableTexts = textKeys
      .filter((key) => !!submittedData[key])
      .map((key) => convertToTextObject(key, submittedData[key]))

    const deletedTextsVariables = textKeys
      .filter((key) => !submittedData[key] && content.texts[key])
      .map((key) => ({
        key,
        model: 'Content',
        modelId: content.id
      }))

    if (image.file && !(image.file as any).id) {
      try {
        await uploadFile({
          file: image.file as File,
          data: {
            key: 'image',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (error) {
        setUploadError(error)
      }
    }

    if (iconImage.file && !(iconImage.file as any).id) {
      try {
        await uploadFile({
          file: iconImage.file as File,
          data: {
            key: 'iconImage',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (error) {
        setUploadError(error)
      }
    }

    if (voiceover.file && !(voiceover.file as any).id) {
      try {
        await uploadFile({
          file: voiceover.file as File,
          data: {
            key: 'voiceover',
            replace: true,
            model: 'Content',
            modelId: content.id
          }
        })
      } catch (error) {
        setUploadError(error)
      }
    }

    if (deletedTextsVariables.length) {
      try {
        await deleteTexts(deletedTextsVariables)
      } catch (e) {
        setUploadError(e)
      }
    }

    if (methods.formState.isDirty) {
      setLoading(true)

      const data: UpdateContentInput = {
        nuggetId,
        texts: translatableTexts,
        order: content.order,
        type: content.type,
        blockedById: submittedData.blockedById || null
      }

      try {
        await updateContent(content.id, data)
      } catch (e) {
        setUploadError(e)
      }
    }

    setLoading(false)
    setShowSnackbar(true)
    refetch?.()
  })

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [content])

  const closeSnackbar = () => {
    setShowSnackbar(false)
    methods.reset()
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'hidden' }}
      onSubmit={onSubmit}
    >
      <Stack spacing={2} p={4} flex={1} sx={{ overflowY: 'auto' }}>
        <TextField
          sx={{ marginBottom: '40px', width: '500px' }}
          label={t('common.title')}
          {...methods.register('title')}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          sx={{ marginBottom: '40px', width: '500px' }}
          label={t('common.description')}
          {...methods.register('description')}
        />
        <TextField
          sx={{ paddingBottom: '40px', width: '500px' }}
          label={t('edit.content.confirmationText')}
          {...methods.register('interactionButtonText')}
          InputLabelProps={{ shrink: true }}
        />
        <Box sx={{ flex: '0 0 500px' }}>
          <Chip label={t('common.image')} size="small" sx={{ marginBottom: '8px' }} />
          <FileDragDrop
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
            height="auto"
            minHeight="200px"
            preview
            limit={1}
            onFilesChanged={(files) => addImage(files[0], 'image', image?.file as ContentFile)}
          >
            {!image.file
              ? <Typography>{t('edit.poi.dragImage')}</Typography>
              : <Box width="100%"
                  height="100%"
                  sx={{
                    backgroundImage: `url(${
                      (image.file as any).url
                        ? (image.file as ContentFile).url
                        : URL.createObjectURL(image.file as File)})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center'
                  }}
              />}
          </FileDragDrop>
          <Chip label={t('edit.content.iconImage')} size="small" sx={{ marginBottom: '8px', marginTop: '40px' }} />
          <FileDragDrop
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
            height="auto"
            minHeight="200px"
            preview
            limit={1}
            onFilesChanged={(files) => addImage(files[0], 'iconImage', iconImage?.file as ContentFile)}
          >
            {!iconImage.file
              ? <Typography>{t('edit.poi.dragImage')}</Typography>
              : <Box width="100%"
                  height="100%"
                  sx={{
                    backgroundImage: `url(${
                      (iconImage.file as any).url
                        ? (iconImage.file as ContentFile).url
                        : URL.createObjectURL(iconImage.file as File)})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center center'
                  }}
              />}
          </FileDragDrop>
          <Box mt={4}>
            <Chip label="Voiceover" size="small" sx={{ marginBottom: '8px' }} />
            <FileDragDrop
              accept={{ 'audio/*': ['.mp3', '.mp4', '.acc'] }}
              preview
              limit={1}
              onFilesChanged={(files) => addVoiceover(files[0], 'voiceover', voiceover.file as ContentFile)}
            >
              <Typography>{
              !voiceover.file
                ? t('edit.poi.dragAudio')
                : (voiceover.file as ContentFile).fileName}</Typography>
            </FileDragDrop>
          </Box>
        </Box>
        <ContentBlockEditor content={content} />
      </Stack>

      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={loading}
        uploadError={uploadError}
        showSnackbar={showSnackbar}
        closeSnackbar={closeSnackbar}
      />
    </form>
  </FormProvider>
}
