import { MuiIconNames } from '@features/cms/typings/material-icon-font'

export const videoConfigSchema = {
  type: 'object',
  properties: {
    fullsize: { type: 'boolean', description: 'Volle Breite. Default: false' },
    fullscreen: { type: 'boolean', description: 'Default: false' },
    autoPlay: { type: 'boolean', description: 'Default: false' },
    loop: { type: 'boolean', description: 'Default: false' },
    trigger: { type: 'string', enum: ['button', 'shake', 'pinch'] },
    buttonPosition: { type: 'string', enum: ['above', 'below'], description: 'wenn trigger = "button"' },
    buttonIcon: { type: 'string', enum: MuiIconNames, description: 'wenn trigger = "button"' },
    buttonProps: { type: 'object', description: 'wenn trigger = "button"' },
    pinchValue: { type: 'number', description: 'wenn trigger = "pinch". default: 2' }
  }
}
