import React from 'react'
import MDEditor, { commands } from '@uiw/react-md-editor'
import rehypeSanitize from 'rehype-sanitize'
import { Box, BoxProps } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

type Props = {
  name: string,
  width?: string | number,
  height?: number,
  style?: BoxProps['style']
}

const editorCommands = [
  commands.bold,
  commands.italic,
  commands.strikethrough,
  commands.divider,
  commands.unorderedListCommand,
  commands.orderedListCommand
]

const extraCommands = [
  commands.codeEdit,
  commands.codeLive,
  commands.codePreview
]

export const MarkdownEditor: React.FC<Props> = ({ name, width, height = 300, style }) => {
  const { control } = useFormContext()

  return (
    <Box width={width} style={style}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <MDEditor
            value={value}
            onChange={onChange}
            height={height}
            commands={editorCommands}
            extraCommands={extraCommands}
            spellCheck={true}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]]
            }}
          />)}
      ></Controller>
    </Box>
  )
}
