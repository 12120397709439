import FileDragDrop from '@features/cms/components/FileDragDrop'
import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { Box, Chip, TextareaAutosize, Typography } from '@mui/material'
import { NuggetContent } from '@views/poi/EditContentPanel'
import React, { useEffect, useState } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { MediaInfos } from './MediaInfos'

type Props = {
  label?: string,
  index: number,
  content: NuggetContent,
  fileKey: string,
  textKey: string,
  markdown?: boolean
  formMethods?: any
}

export const VoiceoverEditor: React.FC<Props> = ({ label, index, content, fileKey, textKey, markdown, formMethods }) => {
  const { t } = useTranslation()
  const { setValue, control } = useFormContext()
  const [voiceoverText, setVoiceoverText] = useState<string | undefined>(content.texts[textKey])

  const addVoiceover = (file: File) => {
    setValue(`voiceover.${index}.editableFile.file`, file, { shouldDirty: true })
  }

  const voiceoverFile = useWatch({
    control,
    name: `voiceover.${index}.editableFile.file`
  })

  useEffect(() => {
    setValue(`voiceover.${index}.text`, {
      key: textKey,
      value: voiceoverText
    }, { shouldDirty: true })
  }, [voiceoverText])

  return <Box marginTop="16px" paddingBottom={4} display="flex" flexDirection="column">
    {label && <Typography variant="h6" sx={{ marginBottom: '24px' }}>{label}</Typography>}
    <Box display="block">
      <Chip label={textKey} size="small" sx={{ marginBottom: '8px' }} />
    </Box>
    {markdown
      ? <MarkdownEditor
          name={`voiceover.${index}.text.value`}
          style={{ marginBottom: '60px' }}
      />
      : <Controller
          name={`voiceover.${index}.text.value`}
          control={control}
          render={() => (
            <TextareaAutosize
              value={formMethods.watch(`voiceover.${index}.text.value`)}
              minRows={3}
              style={{ width: 600, marginBottom: '60px' }}
              onChange={(e) => setVoiceoverText(e.target.value)}
            />)}
      ></Controller>}
    <Box display="block">
      <Chip label={fileKey} size="small" sx={{ marginBottom: '8px' }} />
    </Box>
    <FileDragDrop
      accept={{ 'audio/*': ['.mp3', '.mp4', '.acc'] }}
      height="100%"
      preview
      limit={1}
      onFilesChanged={(files) => addVoiceover(files[0])}
    >
      {!voiceoverFile
        ? <Typography>{t('edit.poi.dragAudio')}</Typography>
        : <>
          <Typography>{voiceoverFile.fileName}</Typography>
          <MediaInfos file={voiceoverFile}></MediaInfos>
        </>
      }
    </FileDragDrop>
    {voiceoverFile &&
      <ReactAudioPlayer
        src={!voiceoverFile.id ? URL.createObjectURL(voiceoverFile) : voiceoverFile.url}
        controls
        style={{ marginBottom: '60px', marginTop: '12px' }}
      />
    }
  </Box>
}
