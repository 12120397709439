import { ContentDataFragment } from '@typings/graphql'

import { ChoiceContentEditor } from './ChoiceContentEditor'
import { ImageZoomContentEditor } from './ImageZoomContentEditor'
import { InfoPopupContentEditor } from './InfoPopupContentEditor'
import { MiniGameHoneycombEditor } from './MiniGameHoneycombEditor'
import { MiniGameFrogDodgeEditor } from './MiniGameFrogDodgeEditor'
import { PanoramaImageContentEditor } from './PanoramaImageContentEditor'
import { SequenceContentEditor } from './SequenceContentEditor'
import { SortableListContentEditor } from './SortableListContentEditor'
import { SwipeContentEditor } from './SwipeContentEditor'
import { VoiceoverContentEditor } from './VoiceoverContentEditor'
import { EstimationContentEditor } from './EstimationContentEditor'
import { MiniGameBugRaceEditor } from './MiniGameBugRaceEditor'
import { InfoCardsContentEditor } from './InfoCardsContentEditor'
import { MediaContentEditor } from './MediaContentEditor'
import { LottieContentEditor } from './LottieContentEditor'
import { CountdownCounterContentEditor } from './CountdownCounterContentEditor'
import { ControlledLottieContentEditor } from './ControlledLottieContentEditor'

export type ContentEditorComponentProps = {
  nuggetId: string,
  content: ContentDataFragment,
  refetch?: Function,
  onEdited?: Function,
}

export const contentTypeEditors = {
  choice: ChoiceContentEditor,
  controlledLottie: ControlledLottieContentEditor,
  countdownCounter: CountdownCounterContentEditor,
  estimation: EstimationContentEditor,
  imageZoom: ImageZoomContentEditor,
  infoCards: InfoCardsContentEditor,
  infoPopup: InfoPopupContentEditor,
  lottie: LottieContentEditor,
  media: MediaContentEditor,
  miniGameBugRace: MiniGameBugRaceEditor,
  miniGameHoneycomb: MiniGameHoneycombEditor,
  miniGameFrogDodge: MiniGameFrogDodgeEditor,
  panoramaImage: PanoramaImageContentEditor,
  sequence: SequenceContentEditor,
  sortableList: SortableListContentEditor,
  swipe: SwipeContentEditor,
  voiceover: VoiceoverContentEditor
}

export default contentTypeEditors
