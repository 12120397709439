import { NuggetContent } from '@views/poi/EditContentPanel'

import { ArContentEditor } from './ArContentEditor'
import { CarouselContentEditor } from './CarouselContentEditor'
import { ImageContentEditor } from './ImageContentEditor'
import { TextContentEditor } from './TextContentEditor'
import { VoiceoverContentEditor } from './VoiceoverContentEditor'
import { ComparisonSliderEditor } from './ComparisonSliderEditor'
import { CompassContentEditor } from './CompassContentEditor'
import { VideoContentEditor } from './VideoContentEditor'
import { PollContentEditor } from './PollContentEditor'
import { AnnotatedVideoContentEditor } from './AnnotatedVideoContentEditor'
import { InfoCarouselContentEditor } from './InfoCarouselContentEditor'
import { EstimationSliderContentEditor } from './EstimationSliderContentEditor'
import { QuizContentEditor } from './QuizContentEditor'
import { CustomContentEditor } from './CustomContentEditor'
import { LiveInteractionCardEditor } from './LiveInteractionCardEditor'
import { CameraViewContentEditor } from './CameraViewContentEditor'
import { QuickTipContentEditor } from './QuickTipContentEditor'
import { AudioCarouselContentEditor } from './AudioCarouselContentEditor'
import { AudioPlayerContentEditor } from './AudioPlayerContentEditor'
import { ScratchCardContentEditor } from './ScratchCardContentEditor'
import { MultipleChoicePollContentEditor } from './MultipleChoicePollContentEditor'
import { FeaturedPoisContentEditor } from './FeaturedPoisContentEditor'
import { SwipeCardsSurveyContentEditor } from './SwipeCardsSurveyContentEditor'
import { SwipeCardsTestContentEditor } from './SwipeCardsTestContentEditor'
import { MarkdownContentEditor } from './MarkdownContentEditor'
import { AudioMemoContentEditor } from './AudioMemoContentEditor'
import { VideoQuizContentEditor } from './VideoQuizContentEditor'
import { LinkBoxContentEditor } from './LinkBoxContentEditor'

export type ContentEditorComponentProps = {
  nuggetId: string,
  content: NuggetContent,
  refetch?: Function,
  onEdited?: Function,
}

export const contentTypeEditors = {
  text: TextContentEditor,
  voiceover: VoiceoverContentEditor,
  ar: ArContentEditor,
  carousel: CarouselContentEditor,
  comparisonSlider: ComparisonSliderEditor,
  estimationSlider: EstimationSliderContentEditor,
  compass: CompassContentEditor,
  image: ImageContentEditor,
  infoCarousel: InfoCarouselContentEditor,
  video: VideoContentEditor,
  poll: PollContentEditor,
  multipleChoicePoll: MultipleChoicePollContentEditor,
  markdown: MarkdownContentEditor,
  quiz: QuizContentEditor,
  annotatedVideo: AnnotatedVideoContentEditor,
  liveInteractionCard: LiveInteractionCardEditor,
  custom: CustomContentEditor,
  cameraView: CameraViewContentEditor,
  quickTip: QuickTipContentEditor,
  audioCarousel: AudioCarouselContentEditor,
  audioPlayer: AudioPlayerContentEditor,
  scratchCard: ScratchCardContentEditor,
  featuredPois: FeaturedPoisContentEditor,
  swipeCardsSurvey: SwipeCardsSurveyContentEditor,
  swipeCardsTest: SwipeCardsTestContentEditor,
  audioMemo: AudioMemoContentEditor,
  videoQuiz: VideoQuizContentEditor,
  linkBox: LinkBoxContentEditor
}

export default contentTypeEditors

// interactiveZoom: InteractiveZoomContentEditor
// animatedNumber: AnimatedNumberContentEditor
